import { endOfDay, lastDayOfMonth, setDate, startOfDay } from 'date-fns'

import { Urls } from '../../../api/urls'
import { SortKeys } from '../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../dataTable/providers/DataTableProvider'
import { UsageDataTable } from '../components/UsageDataTable'

export const UsageScreen = () => {
  return (
    <DataTableProvider
      paginationType={PaginationType.Infinite}
      url={Urls.employerChargingSessions}
      defaultSort="id"
      defaultSortDirection={SortKeys.DESCENDING}
      initialFilterValues={{
        dateFrom: startOfDay(setDate(new Date(), 1)),
        dateTo: endOfDay(lastDayOfMonth(new Date())),
      }}
      standaloneFilterKeys={['dateFrom', 'dateTo']}
      amount={30}
    >
      <UsageDataTable />
    </DataTableProvider>
  )
}
