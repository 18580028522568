import i18next from 'i18next'
import { useEffect, type FC } from 'react'
import { useSetRecoilState } from 'recoil'

import trans_en from '../../../../../packages/translations/en/export.json'
import trans_fr from '../../../../../packages/translations/fr/export.json'
import trans_nl from '../../../../../packages/translations/nl/export.json'
import { useAxios } from '../api/hooks/useAxios'
import { Urls } from '../api/urls'
import { translationsLoadedAtom } from '../core/recoil/atoms'

type TranslationsResponse = {
  nl: Record<string, string>
  fr: Record<string, string>
  en: Record<string, string>
}

const fallbackTranslations = {
  nl: trans_nl,
  fr: trans_fr,
  en: trans_en,
  cimode: {},
}

// Initialise this as soon as possible under the recoil provider
export const TranslationsLoader: FC = () => {
  // -- State --
  const setTranslationsLoaded = useSetRecoilState(translationsLoadedAtom)

  // -- Data --
  const [, getTranslations] = useAxios<TranslationsResponse>(
    {
      url: Urls.Translations,
    },
    {
      manual: true,
    }
  )

  // -- Effects --
  useEffect(() => {
    const fetchTranslations = async () => {
      // Fetch all translations in a single request

      try {
        const { data } = await getTranslations()

        i18next.addResourceBundle('nl', 'translation', {
          ...fallbackTranslations.nl,
          ...data.nl,
        })
        i18next.addResourceBundle('fr', 'translation', {
          ...fallbackTranslations.fr,
          ...data.fr,
        })
        i18next.addResourceBundle('en', 'translation', {
          ...fallbackTranslations.en,
          ...data.en,
        })
      } catch {
        console.error('Failed to fetch translations, reverting to bundled ones')
      }

      setTranslationsLoaded(true)
    }

    fetchTranslations()
  }, [])

  return null
}
