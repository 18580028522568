import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import i18next from 'i18next'
import { usePostHog } from 'posthog-js/react'
import { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts'
import { getLoginMethod } from 'utils/src'

import { LocalStorageKeys } from '../../../config/constants/local-storage-keys'
import { breakpoints } from '../../../theme/layout/breakpoints'
import { useAuthAxios } from '../../api/hooks/useAuthAxios'
import { Urls } from '../../api/urls'
import { useAuthentication } from '../../authentication/hooks/useAuthentication'
import { useSplashScreen } from '../../authentication/hooks/useSplashScreen'
import { FullPageLoader } from '../../components/general/FullPageLoader'
import { inviteIdAtom } from '../../core/recoil/atoms'
import { useIntercom } from '../../intercom/hooks/useIntercom'
import { pushTokenAtom } from '../../notification/components/RegisterNotifications'

import type { RefetchFunction } from '../../api/hooks/types'
import type { AxiosPromise } from 'axios'
import type { User } from 'database'
import type { PropsWithChildren } from 'react'
import type { FullUser } from 'types'

type UserContext = {
  user: FullUser
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: RefetchFunction<any, FullUser>
  updateUser: (data: Partial<User>) => AxiosPromise<FullUser>
  updateLoading: boolean
}

export const userContext = createContext<UserContext | null>(null)

export const UserProvider = ({ children }: PropsWithChildren) => {
  // -- State --
  const inviteId = useRecoilValue(inviteIdAtom)
  const pushToken = useRecoilValue(pushTokenAtom)
  const [user, setUser] = useState<FullUser>()

  // Hooks
  const intercom = useIntercom()
  const posthog = usePostHog()
  const { logout } = useAuthentication()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { i18n } = useTranslation()
  const { hideSplashScreen, translationsAreLoaded } = useSplashScreen()
  const localStorageMemberId = useReadLocalStorage(
    LocalStorageKeys.CURRENT_MEMBER
  )

  // -- Effects --
  useEffect(() => {
    if (user && translationsAreLoaded) {
      hideSplashScreen()
    }
  }, [user, translationsAreLoaded, hideSplashScreen])

  // -- Data --
  const [{ data: getUserData, loading, error }, refetch] =
    useAuthAxios<FullUser>(
      // Add inviteId if found in state
      `${Urls.User}${
        inviteId
          ? `?inviteId=${inviteId}&language=${
              i18n.language === 'cimode' ? 'nl' : i18n.language
            }`
          : ''
      }`
    )
  const [, fetchIntercomHashes] = useAuthAxios<{
    web: string
    android: string
    ios: string
  }>(
    {},
    {
      manual: true,
    }
  )

  const [, sendPushToken] = useAuthAxios(
    {
      url: Urls.subscribeToPush,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  const currentMember = localStorageMemberId
    ? user?.members.find((member) => member.id === localStorageMemberId)
    : user?.members?.[0]

  const [{ data: updateUserData, loading: updateLoading }, executeUpdate] =
    useAuthAxios<FullUser>(
      {
        url: Urls.User,
        method: 'Patch',
        params: {
          memberId: currentMember?.id,
        },
      },
      {
        manual: true,
      }
    )

  // -- Vars --
  const fullName =
    (getUserData?.firstName || '') +
    (getUserData?.lastName ? ` ${getUserData?.lastName}` : '')

  const nativeUrl = `${import.meta.env.VITE_APP_ID}://${
    import.meta.env.VITE_AUTH0_DOMAIN
  }/capacitor/${import.meta.env.VITE_APP_ID}`

  const localStorageLanguage = localStorage.getItem(
    LocalStorageKeys.SELECTED_LANGUAGE
  )

  // -- Handlers --
  const updateUser = (data: Partial<User>) => {
    return executeUpdate({
      data,
    })
  }

  // Effects
  useEffect(() => {
    const asyncFunction = async () => {
      if (getUserData) {
        setUser(getUserData)

        // Redefine currentMember because it's only available in the next tick
        const currentMember = localStorageMemberId
          ? getUserData.members.find(
              (member) => member.id === localStorageMemberId
            )
          : getUserData.members?.[0]

        // Don't force user language when CiMode is set except for production
        if (
          localStorageLanguage !== 'cimode' ||
          import.meta.env.VITE_ENV === 'production'
        ) {
          i18next.changeLanguage(getUserData.language)
          localStorage.setItem(
            LocalStorageKeys.SELECTED_LANGUAGE,
            getUserData.language
          )
        }

        let hashes = {}
        try {
          ;({ data: hashes } = await fetchIntercomHashes(
            `${Urls.IntercomHashes}/${getUserData.id}`
          ))
        } catch {
          // Do nothing.
        }

        // Intercom
        intercom.boot(
          {
            userId: getUserData.id,
            name: fullName,
            email: getUserData.email || '',
            phone: getUserData.tel || '',
            customAttributes: {
              // Attributes are capitalized and have spaces to match presentation in Intercom
              'Zoho contact': import.meta.env.VITE_ZOHO_CONTACT_BASE_URL
                ? `${import.meta.env.VITE_ZOHO_CONTACT_BASE_URL}/${
                    getUserData.zohoId
                  }`
                : '',
              'Zoho company':
                import.meta.env.VITE_ZOHO_ACCOUNT_BASE_URL &&
                currentMember?.company.zohoId
                  ? `${import.meta.env.VITE_ZOHO_ACCOUNT_BASE_URL}/${
                      currentMember.company.zohoId
                    }`
                  : '',
              Company: currentMember?.company.name ?? '',
              'Posthog user': import.meta.env.VITE_POSTHOG_PERSON_BASE_URL
                ? `${import.meta.env.VITE_POSTHOG_PERSON_BASE_URL}/${
                    getUserData.id
                  }`
                : '',
              'Optimile CPO':
                import.meta.env.VITE_OPTIMILE_BASE_URL &&
                getUserData.addresses[0]?.devices[0]?.optimileId
                  ? `${
                      import.meta.env.VITE_OPTIMILE_BASE_URL
                    }/co/admin/devices/${
                      getUserData.addresses[0].devices[0].optimileId
                    }/#/config`
                  : '',
              'Optimile Maintenance':
                import.meta.env.VITE_OPTIMILE_BASE_URL &&
                getUserData.addresses[0]?.devices[0]?.optimileId
                  ? `${
                      import.meta.env.VITE_OPTIMILE_BASE_URL
                    }/maintenance/device/${
                      getUserData.addresses[0].devices[0].optimileId
                    }`
                  : '',
              'Blossom ID': getUserData.blossomId,
              Role: getUserData.members.some((member) =>
                member.roles.includes('Admin')
              )
                ? getUserData.members.some((member) =>
                    member.roles.includes('SelfEmployed')
                  )
                  ? 'Self employed'
                  : 'Fleet manager'
                : 'User',
              'Has Home Charger':
                getUserData.addresses[0]?.devices.length > 0 ? 'Yes' : 'No',
              'Login method': getLoginMethod(getUserData),
            },
            hideDefaultLauncher: !isDesktop,
            languageOverride: getUserData.language,
          },
          hashes
        )

        if (pushToken) {
          sendPushToken({
            data: {
              token: pushToken,
              platform: Capacitor.getPlatform(),
            },
          })
        }
      }
    }

    asyncFunction()
  }, [getUserData, isDesktop])

  useEffect(() => {
    if (user) {
      const asyncFunction = async () => {
        SplashScreen.hide({ fadeOutDuration: 250 })
      }

      // App is ready!
      if (Capacitor.isNativePlatform()) {
        asyncFunction()
      }
    }
  }, [user])

  useEffect(() => {
    if (getUserData) {
      posthog.identify(getUserData.id, {
        email: getUserData.email || '',
        name: fullName,
        blossom_id: getUserData.blossomId,
      })
    }
  }, [posthog, getUserData])

  useEffect(() => {
    if (error) {
      logout({
        logoutParams: Capacitor.isNativePlatform()
          ? {
              returnTo: inviteId
                ? `${nativeUrl}/login-error?inviteId=${inviteId}`
                : `${nativeUrl}/login-error`,
            }
          : {
              returnTo: inviteId
                ? `${window.location.origin}/login-error?inviteId=${inviteId}`
                : `${window.location.origin}/login-error`,
            },
      })
    }
  }, [error])

  useEffect(() => {
    if (updateUserData) {
      setUser(updateUserData)

      // Intercom
      intercom.update({
        name: fullName,
        email: updateUserData.email || '',
        phone: updateUserData.tel || '',
        languageOverride: updateUserData.language,
      })
    }
  }, [updateUserData])

  if ((loading && !user) || !user || !translationsAreLoaded) {
    return <FullPageLoader />
  }

  return (
    <userContext.Provider
      value={{
        user,
        refetch,
        updateUser,
        updateLoading,
      }}
    >
      {children}
    </userContext.Provider>
  )
}
