import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthAxios } from '../../../api/hooks/useAuthAxios'
import { Urls } from '../../../api/urls'
import { useAuthentication } from '../../../authentication/hooks/useAuthentication'
import { onboardingOriginAtom } from '../../../core/recoil/atoms'
import { useMember } from '../../../member/hooks/useMember'
import { useUser } from '../../../user/hooks/useUser'
import { hcpOnboardingWizardConfig } from '../config/wizardConfig'
import { EnergyContractOwnerKeys } from '../steps/account/EnergyContractOwnerStep'
import { UseReimbursementsKeys } from '../steps/account/UseReimbursementsStep'
import { ChargerLocationKeys } from '../steps/preferences/ChargerLocationStep'
import { DigitalMeterKeys } from '../steps/preferences/DigitalMeterStep'
import { InstallationMethodKeys } from '../steps/preferences/InstallationMethodStep'
import { PrivateTerrainKeys } from '../steps/preferences/PrivateTerrainStep'

import type { CurrentStep } from '../../../components/navigation/Wizard'
import type {
  P1PortActivated,
  Socket,
  UtpConnection,
  WifiSignal,
} from '../steps/preferences/DigitalMeterEnvironmentStep'

export enum OnboardingSteps {
  // Invite steps
  Welcome = 'welcome',
  Intercom = 'intercom',
  CloseExplanation = 'close',

  // Onboarding steps
  // Account
  VerifyEmail = 'verifyEmail',
  PersonalData = 'personalData',
  SolutionSelection = 'solutionSelection',
  InstallationAddress = 'installationAddress',
  EnergyContractOwner = 'energyContractOwner',
  EnergyContractOwnerNotImplemented = 'energyContractOwnerNotImplemented',
  UseReimbursements = 'useReimbursements',
  BankDetails = 'bankDetails',
  // Preferences
  ChargerLocation = 'chargerLocation',
  ChargerLocationInput = 'chargerLocationInput',
  PrivateTerrain = 'privateTerrain',
  Rejected = 'rejected',
  InstallationMethod = 'installationMethod',
  WallMaterial = 'wallMaterial',
  ProductSelection = 'productSelection',
  DigitalMeter = 'digitalMeter',
  DigitalMeterEnvironment = 'digitalMeterEnvironment',
  ConnectionType = 'connectionType',
  CableDistance = 'cableDistance',
  CableDigWork = 'cableDigWork',
  UploadDesktop = 'uploadDesktop',
  CableRoute = 'cableRoute',
  ChargerDisassemble = 'chargerDisassemble',
  ExtraComment = 'extraComment',
  Overview = 'overview',

  // Mobile Upload
  ElectricityBoxImage = 'electricityBoxImage',
  FuseBoxImage = 'fuseBoxImage',
  ElectricityMeterImage = 'electricityMeterImage',
  ChargerLocationImage = 'chargerLocationImage',
  ExtraImages = 'extraImages',
  ContinueInTheApp = 'continueInTheApp',
}

const getCurrentStep = (
  data: Partial<OnboardingValues>,
  isDesktop: boolean,
  emailVerified: boolean,
  userHasOptimileId: boolean,
  userHasExistingDevice: boolean,
  userHasNoContracts: boolean,
  selfEmployed: boolean
): OnboardingSteps => {
  if (!emailVerified && import.meta.env.VITE_ENV !== 'local') {
    return OnboardingSteps.VerifyEmail
  }

  if (
    (selfEmployed
      ? data?.personalData?.companyAddress?.street === ''
      : data?.personalData?.address?.street === '') ||
    !userHasOptimileId
  ) {
    return OnboardingSteps.PersonalData
  }

  if (selfEmployed && userHasNoContracts) {
    return OnboardingSteps.SolutionSelection
  }

  if (selfEmployed && data?.personalData?.address?.street === '') {
    return OnboardingSteps.InstallationAddress
  }

  if (selfEmployed && !data?.energyContractOwnerKey) {
    return OnboardingSteps.EnergyContractOwner
  }

  if (
    selfEmployed &&
    data?.energyContractOwnerKey === EnergyContractOwnerKeys.Company
  ) {
    return OnboardingSteps.EnergyContractOwnerNotImplemented
  }

  if (selfEmployed && !data?.useReimbursementsKey) {
    return OnboardingSteps.UseReimbursements
  }

  if (
    (!data.bankDetails?.iban && !selfEmployed) ||
    // We skip this step if the user does not use reimbursements
    (selfEmployed &&
      !data.bankDetails?.iban &&
      data?.useReimbursementsKey === UseReimbursementsKeys.Yes)
  ) {
    return OnboardingSteps.BankDetails
  }

  if (!data.chargerLocationKey && !userHasExistingDevice) {
    return OnboardingSteps.ChargerLocation
  }

  if (
    !data.chargerLocation &&
    data.chargerLocationKey === ChargerLocationKeys.Other &&
    !userHasExistingDevice
  ) {
    return OnboardingSteps.ChargerLocationInput
  }

  if (!data.privateTerrainKey && !userHasExistingDevice) {
    return OnboardingSteps.PrivateTerrain
  }

  if (
    data.privateTerrainKey === PrivateTerrainKeys.No &&
    !userHasExistingDevice
  ) {
    return OnboardingSteps.Rejected
  }

  if (!data.installationMethodKey && !userHasExistingDevice) {
    return OnboardingSteps.InstallationMethod
  }

  if (!data.product && !userHasExistingDevice) {
    return OnboardingSteps.ProductSelection
  }

  if (
    !data.installationMaterialKey &&
    data.installationMethodKey === InstallationMethodKeys.Wall &&
    !userHasExistingDevice
  ) {
    return OnboardingSteps.WallMaterial
  }

  if (!data.digitalMeterKey) {
    return OnboardingSteps.DigitalMeter
  }

  if (data.digitalMeterKey === DigitalMeterKeys.Yes) {
    const isDigitalMeterEnvironmentFilledIn = data.digitalMeterEnvironment
      ? Object.values(data.digitalMeterEnvironment).every(Boolean)
      : false

    if (!isDigitalMeterEnvironmentFilledIn) {
      return OnboardingSteps.DigitalMeterEnvironment
    }
  }

  if (!data.connectionType?.gridConnection || !data.connectionType?.amperage) {
    return OnboardingSteps.ConnectionType
  }

  if (!data.cableLengthKey) {
    return OnboardingSteps.CableDistance
  }

  if (!data.digWorkKey) {
    return OnboardingSteps.CableDigWork
  }

  // Desktop upload flow check
  if (
    isDesktop &&
    !(
      data.fuseBoxImageIds &&
      data.electricalBoxImageIds &&
      data.chargerLocationImageIds &&
      data.electricityMeterImageIds
    )
  ) {
    return OnboardingSteps.UploadDesktop
  }

  // Mobile upload flow check
  if (!data.electricalBoxImageIds) {
    return OnboardingSteps.ElectricityBoxImage
  }

  if (!data.fuseBoxImageIds) {
    return OnboardingSteps.FuseBoxImage
  }

  if (!data.electricityMeterImageIds) {
    return OnboardingSteps.ElectricityMeterImage
  }

  if (!data.chargerLocationImageIds) {
    return OnboardingSteps.ChargerLocationImage
  }

  if (!isDesktop && !data.skippedExtraImages && !data.extraImageIds?.length) {
    return OnboardingSteps.ExtraImages
  }

  if (!data.cableRoute) {
    return OnboardingSteps.CableRoute
  }

  if (!data.chargerDisassembleKey) {
    return OnboardingSteps.ChargerDisassemble
  }

  if (!data.extraComment && !data.skippedExtraComment) {
    return OnboardingSteps.ExtraComment
  }

  return OnboardingSteps.Overview
}

type OnboardingConfigOption = {
  next: (alternative?: boolean) => OnboardingSteps
  previous: ({
    useReimbursementsKey,
  }: {
    useReimbursementsKey: string
  }) => OnboardingSteps
}

export type OnboardingValues = {
  personalData: {
    firstName: string
    lastName: string
    address: {
      street: string
      number: string
      country: string
      countryName: string
      city: string
      postcode: string
      longitude: number
      latitude: number
    }
    companyAddress: {
      street: string
      number: string
      country: string
      city: string
      postcode: string
    }
    tel: string
    companyName: string
    vatNumber: string
  }
  bankDetails: {
    iban: string
  }
  chargerLocationKey: string
  chargerLocation: string
  privateTerrainKey: string
  installationMethodKey: string
  installationMaterialKey: string | null
  digitalMeterKey: string
  digitalMeterEnvironment: {
    utpConnectionKey: UtpConnection | null
    wifiSignalKey: WifiSignal | null
    socketKey: Socket | null
    p1PortActivatedKey: P1PortActivated | null
  }
  connectionType: {
    gridConnection: string
    amperage: string
  }
  digWorkKey: string
  cableLengthKey: string
  product: string
  color: string
  connectionMethod: string
  mountingOption: string
  electricalBoxImageIds?: string[]
  fuseBoxImageIds?: string[]
  electricityMeterImageIds?: string[]
  chargerLocationImageIds?: string[]
  extraImageIds?: string[]
  cableRoute: string
  chargerDisassembleKey: string
  extraComment: string
  skippedExtraImages?: boolean
  skippedExtraComment?: boolean
  energyContractOwnerKey: string
  useReimbursementsKey: string
}

export const useOnboardingState = () => {
  // -- State --
  const [formState, setFormState] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [onboardingIsLoading, setOnboardingIsLoading] = useState(true)
  const [showSaved, setShowSaved] = useState(false)
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const [onboardingIsRedirected, setOnboardingIsRedirected] =
    useRecoilState(onboardingOriginAtom)

  // -- Hooks --
  const { user: auth0User } = useAuthentication()
  const { user } = useUser()
  const { currentMember } = useMember()
  const { trackEvent } = useTracking()

  // -- Data --
  const [{ data, error }] = useAuthAxios<Partial<OnboardingValues>>(
    Urls.Onboarding
  )

  // -- Vars --
  const mspFlow = user.hcpStatus.status === 'Inapplicable'
  const selfEmployedFlow = currentMember.roles.includes('SelfEmployed')
  const existingHcpFlow = user.hcpStatus.hasExistingDevice

  const simplifiedFlow = mspFlow || existingHcpFlow

  const currentStep = formState.get('step') as OnboardingSteps
  const initialData = {
    chargerLocationKey: '',
    chargerLocation: '',
    privateTerrainKey: '',
    installationMethodKey: '',
    installationMaterialKey: '',
    digitalMeterKey: '',
    connectionType: {
      gridConnection: '',
      amperage: '',
    },
    digWorkKey: '',
    cableLengthKey: '',
    personalData: {
      firstName: auth0User?.given_name || '',
      lastName: auth0User?.family_name || '',
      tel: '',
      address: {
        street: '',
        number: '',
        country: '',
        countryName: '',
        city: '',
        postcode: '',
        longitude: 0,
        latitude: 0,
      },
      companyAddress: {
        street: '',
        number: '',
        country: '',
        city: '',
        postcode: '',
      },
      companyName: '',
      vatNumber: '',
    },
    bankDetails: {
      iban: '',
    },
    product: '',
    color: '',
    connectionMethod: '',
    mountingOption: '',
    cableRoute: '',
    chargerDisassembleKey: '',
    extraComment: '',
    skippedExtraImages: false,
    skippedExtraComment: false,
    digitalMeterEnvironment: {
      utpConnectionKey: null,
      wifiSignalKey: null,
      socketKey: null,
      p1PortActivatedKey: null,
    },
    energyContractOwnerKey: '',
    useReimbursementsKey: '',
  }

  const headerStates: Record<
    OnboardingSteps,
    {
      state: CurrentStep
      cantGoBack?: boolean
      cantCancel?: boolean
      canLogout?: boolean
    }
  > = {
    [OnboardingSteps.Welcome]: {
      state: {
        ...hcpOnboardingWizardConfig[0],
        subStep: {
          step: 1,
          of: isDesktop || simplifiedFlow ? 2 : 3,
        },
      },
      cantGoBack: true,
      cantCancel: true,
      canLogout: true,
    },
    [OnboardingSteps.Intercom]: {
      state: {
        ...hcpOnboardingWizardConfig[0],
        subStep: {
          step: 2,
          of: isDesktop || simplifiedFlow ? 2 : 3,
        },
      },
      cantCancel: true,
      canLogout: true,
    },
    [OnboardingSteps.CloseExplanation]: {
      state: {
        ...hcpOnboardingWizardConfig[0],
        subStep: {
          step: 3,
          of: isDesktop ? 2 : 3,
        },
      },
      cantCancel: true,
      canLogout: true,
    },
    // Account
    [OnboardingSteps.VerifyEmail]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 1,
          of: selfEmployedFlow ? 7 : simplifiedFlow ? 2 : 3,
        },
      },
      cantCancel: true,
      canLogout: true,
    },
    [OnboardingSteps.PersonalData]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 2,
          of: selfEmployedFlow ? 7 : simplifiedFlow ? 2 : 3,
        },
      },
      cantCancel: true,
      canLogout: true,
    },
    [OnboardingSteps.SolutionSelection]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 3,
          of: 7, // This step is only available for self-employed
        },
      },
      canLogout: true,
    },
    [OnboardingSteps.InstallationAddress]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 4,
          of: 7, // This step is only available for self-employed
        },
      },
      canLogout: true,
    },
    [OnboardingSteps.EnergyContractOwner]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 5,
          of: 7, // This step is only available for self-employed
        },
      },
      canLogout: true,
    },
    [OnboardingSteps.EnergyContractOwnerNotImplemented]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 5,
          of: 7, // This step is only available for self-employed
        },
      },
      canLogout: true,
    },
    [OnboardingSteps.UseReimbursements]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: 6,
          of: 7, // This step is only available for self-employed
        },
      },
      cantCancel: true,
      canLogout: true,
    },
    [OnboardingSteps.BankDetails]: {
      state: {
        ...hcpOnboardingWizardConfig[1],
        subStep: {
          step: selfEmployedFlow ? 7 : 3,
          of: selfEmployedFlow ? 7 : 3,
        },
      },
    },
    // Preferences
    [OnboardingSteps.ChargerLocation]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 1,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ChargerLocationInput]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 1,
          of: 18,
        },
      },
    },
    [OnboardingSteps.PrivateTerrain]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 2,
          of: 18,
        },
      },
    },
    [OnboardingSteps.Rejected]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        loading: true,
      },
      cantGoBack: true,
    },
    [OnboardingSteps.InstallationMethod]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 3,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ProductSelection]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 4,
          of: 18,
        },
      },
    },
    [OnboardingSteps.WallMaterial]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 5,
          of: 18,
        },
      },
    },
    [OnboardingSteps.DigitalMeter]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: existingHcpFlow ? 1 : 6,
          of: existingHcpFlow ? 2 : 18,
        },
      },
    },
    [OnboardingSteps.DigitalMeterEnvironment]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: existingHcpFlow ? 2 : 7,
          of: existingHcpFlow ? 2 : 18,
        },
      },
    },
    [OnboardingSteps.ConnectionType]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 8,
          of: 18,
        },
      },
    },
    [OnboardingSteps.CableDistance]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 9,
          of: 18,
        },
      },
    },
    [OnboardingSteps.CableDigWork]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 10,
          of: 18,
        },
      },
    },
    [OnboardingSteps.UploadDesktop]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 11,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ElectricityBoxImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 11,
          of: 18,
        },
      },
    },
    [OnboardingSteps.FuseBoxImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 11,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ElectricityMeterImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 13,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ChargerLocationImage]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 14,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ExtraImages]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 15,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ContinueInTheApp]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        loading: true,
      },
    },
    [OnboardingSteps.CableRoute]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 16,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ChargerDisassemble]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 17,
          of: 18,
        },
      },
    },
    [OnboardingSteps.ExtraComment]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        subStep: {
          step: 18,
          of: 18,
        },
      },
    },
    [OnboardingSteps.Overview]: {
      state: {
        ...hcpOnboardingWizardConfig[2],
        completed: true,
      },
    },
  }

  // -- Data --
  const [values, setValues] = useState<OnboardingValues>(initialData)

  const OnboardingConfig: Record<OnboardingSteps, OnboardingConfigOption> = {
    [OnboardingSteps.Welcome]: {
      next: () => OnboardingSteps.Intercom,
      previous: () => OnboardingSteps.Welcome,
    },
    [OnboardingSteps.Intercom]: {
      next: () =>
        isDesktop
          ? auth0User?.email_verified || import.meta.env.VITE_ENV === 'local'
            ? OnboardingSteps.PersonalData
            : OnboardingSteps.VerifyEmail
          : OnboardingSteps.CloseExplanation,
      previous: () => OnboardingSteps.Welcome,
    },
    [OnboardingSteps.CloseExplanation]: {
      next: () =>
        import.meta.env.VITE_ENV === 'local'
          ? OnboardingSteps.PersonalData
          : OnboardingSteps.VerifyEmail,
      previous: () => OnboardingSteps.Intercom,
    },
    // Account
    [OnboardingSteps.VerifyEmail]: {
      next: () => OnboardingSteps.PersonalData,
      previous: () =>
        isDesktop ? OnboardingSteps.Intercom : OnboardingSteps.CloseExplanation,
    },
    [OnboardingSteps.PersonalData]: {
      next: () =>
        selfEmployedFlow
          ? OnboardingSteps.SolutionSelection
          : OnboardingSteps.BankDetails,
      previous: () =>
        isDesktop || simplifiedFlow
          ? OnboardingSteps.Intercom
          : OnboardingSteps.CloseExplanation,
    },
    [OnboardingSteps.SolutionSelection]: {
      next: () => OnboardingSteps.InstallationAddress,
      previous: () => OnboardingSteps.PersonalData,
    },
    [OnboardingSteps.InstallationAddress]: {
      next: () => OnboardingSteps.EnergyContractOwner,
      previous: () => OnboardingSteps.SolutionSelection,
    },
    [OnboardingSteps.EnergyContractOwner]: {
      next: (alternative) =>
        alternative
          ? OnboardingSteps.EnergyContractOwnerNotImplemented
          : OnboardingSteps.UseReimbursements,
      previous: () => OnboardingSteps.InstallationAddress,
    },
    [OnboardingSteps.EnergyContractOwnerNotImplemented]: {
      next: () => OnboardingSteps.EnergyContractOwnerNotImplemented,
      previous: () => OnboardingSteps.EnergyContractOwner,
    },
    [OnboardingSteps.UseReimbursements]: {
      next: (skipNext) =>
        skipNext
          ? existingHcpFlow
            ? OnboardingSteps.DigitalMeter
            : OnboardingSteps.ChargerLocation
          : OnboardingSteps.BankDetails,
      previous: () => OnboardingSteps.EnergyContractOwner,
    },
    [OnboardingSteps.BankDetails]: {
      next: () =>
        existingHcpFlow
          ? OnboardingSteps.DigitalMeter
          : OnboardingSteps.ChargerLocation,
      previous: () =>
        selfEmployedFlow
          ? OnboardingSteps.UseReimbursements
          : OnboardingSteps.PersonalData,
    },
    // Preferences
    [OnboardingSteps.ChargerLocation]: {
      next: (alternative) =>
        alternative
          ? OnboardingSteps.ChargerLocationInput
          : OnboardingSteps.PrivateTerrain,
      previous: ({ useReimbursementsKey }) =>
        selfEmployedFlow && useReimbursementsKey === UseReimbursementsKeys.No
          ? OnboardingSteps.UseReimbursements
          : OnboardingSteps.BankDetails,
    },
    [OnboardingSteps.ChargerLocationInput]: {
      next: () => OnboardingSteps.PrivateTerrain,
      previous: () => OnboardingSteps.ChargerLocation,
    },
    [OnboardingSteps.PrivateTerrain]: {
      next: (alternative) =>
        alternative
          ? OnboardingSteps.Rejected
          : OnboardingSteps.InstallationMethod,
      previous: () =>
        values.chargerLocation?.length > 0
          ? OnboardingSteps.ChargerLocationInput
          : OnboardingSteps.ChargerLocation,
    },
    [OnboardingSteps.Rejected]: {
      next: () => OnboardingSteps.Rejected,
      previous: () => OnboardingSteps.Rejected,
    },
    [OnboardingSteps.InstallationMethod]: {
      next: () => OnboardingSteps.ProductSelection,
      previous: () => OnboardingSteps.PrivateTerrain,
    },
    [OnboardingSteps.ProductSelection]: {
      next: (alternative) =>
        alternative
          ? OnboardingSteps.WallMaterial
          : OnboardingSteps.DigitalMeter,
      previous: () => OnboardingSteps.InstallationMethod,
    },
    [OnboardingSteps.WallMaterial]: {
      next: () => OnboardingSteps.DigitalMeter,
      previous: () => OnboardingSteps.ProductSelection,
    },
    [OnboardingSteps.DigitalMeter]: {
      next: (isDigitalMeter) => {
        return isDigitalMeter
          ? OnboardingSteps.DigitalMeterEnvironment
          : OnboardingSteps.ConnectionType
      },
      previous: () =>
        existingHcpFlow
          ? OnboardingSteps.BankDetails
          : values.installationMethodKey === InstallationMethodKeys.Wall
          ? OnboardingSteps.WallMaterial
          : OnboardingSteps.ProductSelection,
    },
    [OnboardingSteps.DigitalMeterEnvironment]: {
      next: () => OnboardingSteps.ConnectionType,
      previous: () => OnboardingSteps.DigitalMeter,
    },
    [OnboardingSteps.ConnectionType]: {
      next: () => OnboardingSteps.CableDistance,
      previous: () =>
        values.digitalMeterKey === DigitalMeterKeys.Yes
          ? OnboardingSteps.DigitalMeterEnvironment
          : OnboardingSteps.DigitalMeter,
    },
    [OnboardingSteps.CableDistance]: {
      next: () => OnboardingSteps.CableDigWork,
      previous: () => OnboardingSteps.ConnectionType,
    },
    [OnboardingSteps.CableDigWork]: {
      next: () =>
        isDesktop
          ? OnboardingSteps.UploadDesktop
          : OnboardingSteps.ElectricityBoxImage,
      previous: () => OnboardingSteps.CableDistance,
    },
    [OnboardingSteps.UploadDesktop]: {
      next: () => OnboardingSteps.CableRoute,
      previous: () => OnboardingSteps.CableDigWork,
    },
    [OnboardingSteps.ElectricityBoxImage]: {
      next: () => OnboardingSteps.FuseBoxImage,
      previous: () => OnboardingSteps.CableDigWork,
    },
    [OnboardingSteps.FuseBoxImage]: {
      next: () => OnboardingSteps.ElectricityMeterImage,
      previous: () => OnboardingSteps.ElectricityBoxImage,
    },
    [OnboardingSteps.ElectricityMeterImage]: {
      next: () => OnboardingSteps.ChargerLocationImage,
      previous: () => OnboardingSteps.FuseBoxImage,
    },
    [OnboardingSteps.ChargerLocationImage]: {
      next: () => OnboardingSteps.ExtraImages,
      previous: () => OnboardingSteps.ElectricityMeterImage,
    },
    [OnboardingSteps.ExtraImages]: {
      next: () => {
        if (onboardingIsRedirected && !isDesktop) {
          // Reset this value so the user does not see the ContinueDesktopStep again when pressing the back button
          setOnboardingIsRedirected(false)
          return OnboardingSteps.ContinueInTheApp
        }

        return OnboardingSteps.CableRoute
      },
      previous: () => OnboardingSteps.ChargerLocationImage,
    },
    [OnboardingSteps.ContinueInTheApp]: {
      next: () => OnboardingSteps.CableRoute,
      previous: () => OnboardingSteps.ExtraImages,
    },
    [OnboardingSteps.CableRoute]: {
      next: () => OnboardingSteps.ChargerDisassemble,
      previous: () =>
        isDesktop ? OnboardingSteps.UploadDesktop : OnboardingSteps.ExtraImages,
    },
    [OnboardingSteps.ChargerDisassemble]: {
      next: () => OnboardingSteps.ExtraComment,
      previous: () => OnboardingSteps.CableRoute,
    },
    [OnboardingSteps.ExtraComment]: {
      next: () => OnboardingSteps.Overview,
      previous: () => OnboardingSteps.ChargerDisassemble,
    },
    [OnboardingSteps.Overview]: {
      next: () => OnboardingSteps.Overview,
      previous: () => OnboardingSteps.ExtraComment,
    },
  }

  useEffect(() => {
    if (data) {
      const nextStep = getCurrentStep(
        data,
        isDesktop,
        auth0User?.email_verified ?? false,
        !!user.optimileId,
        existingHcpFlow,
        !currentMember.hcpContractId && !currentMember.mspContractId,
        selfEmployedFlow
      )

      setValues({
        ...initialData,
        ...data,
        personalData: {
          ...initialData.personalData,
          ...data.personalData,
          address: {
            ...initialData.personalData.address,
            ...data.personalData?.address,
          },
          companyAddress: {
            ...initialData.personalData.companyAddress,
            ...data.personalData?.companyAddress,
          },
        },
        bankDetails: {
          ...initialData.bankDetails,
          ...data.bankDetails,
        },
      })

      if (
        Object.values(OnboardingSteps).indexOf(currentStep) <=
        Object.values(OnboardingSteps).indexOf(nextStep)
      ) {
        setFormState({ step: currentStep || nextStep }, { replace: true })
      } else {
        setFormState({ step: nextStep }, { replace: true })
      }

      setOnboardingIsLoading(false)
    }
  }, [data])

  // -- Functions --
  const handleNext = (data: OnboardingValues, properties?: boolean) => {
    const nextStep = OnboardingConfig[currentStep].next(properties)

    setValues(data)

    trackEvent(EventType.Submit, `submit_${currentStep}`)

    setShowSaved(true)
    setLoading(false)

    setFormState({ step: nextStep })

    setTimeout(() => {
      setShowSaved(false)
    }, 1500)
  }

  const handlePrevious = () => {
    const previousStep = OnboardingConfig[currentStep].previous({
      useReimbursementsKey: values.useReimbursementsKey,
    })

    trackEvent(EventType.Click, 'go_back_onboarding', {
      from: currentStep,
      to: previousStep,
    })

    setFormState({ step: previousStep })
  }

  // -- Return --
  return {
    currentStep: currentStep,
    headerState: headerStates[currentStep] || headerStates.personalData,
    handleNext,
    handlePrevious,
    loading,
    setLoading,
    showSaved,
    values,
    setValues,
    onboardingIsLoading: onboardingIsLoading || !data,
    simplifiedFlow,
    selfEmployedFlow,
    error,
  }
}
