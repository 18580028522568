import { useMember } from '../../member/hooks/useMember'

import type { AdminPermission } from '@prisma/client'

type MemberPermission = {
  permissions: AdminPermission[]
}

export const usePermissions = () => {
  const { currentMember } = useMember()

  const hasPermission = (permission: AdminPermission): boolean => {
    const memberPermissions = (
      currentMember.permissions as unknown as MemberPermission[]
    )?.[0]?.permissions
    return memberPermissions?.includes(permission) ?? false
  }

  return {
    hasPermission,
  }
}
