import { Capacitor } from '@capacitor/core'
import { DotLottiePlayer } from '@dotlottie/react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconLayer, type Layer, type PickingInfo } from 'deck.gl'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Map as MapBox, Marker } from 'react-map-gl'
import { useKey } from 'react-use'
import { styled, useTheme } from 'styled-components'
import { useDebounceValue, useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import { AddressSearch } from '../../../components/form/address-search/AddressSearch'
import { createMapboxAddressString } from '../../../components/form/address-search/createAddressString'
import { useSafeAreaInsets } from '../../../core/hooks/useSafeAreaInsets'
import { useGeoLocation } from '../../../location/hooks/useGeoLocation'
import { useChargerLocations } from '../hooks/useChargerLocations'

import { AuthenticatedChargeLocationDetail } from './AuthenticatedChargeLocationDetail'
import iconMapping from './data/location-icon-mapping.json'
import iconAtlas from './data/location_icon_atlas.png'
import { DeckGLOverlay } from './DeckGlOverlay'
import { IconClusterLayer, getIconName } from './icon-cluster-layer'
import { MapFilters } from './MapFilters'

import type { MapFiltersType } from './MapFilters'
import type { MapboxAddress } from '../../../components/form/address-search/types'
import type { MapRef } from 'react-map-gl'
import type { ClusterProperties } from 'supercluster'
import type { ChargingLocation, OptimileChargingPointCluster } from 'types'

const CHARGER_ZOOM_LEVEL = 17

type MapProps = {
  startLocation: {
    longitude: number
    latitude: number
  }
}

export const Map = ({ startLocation }: MapProps) => {
  // -- Ref --
  const mapRef = useRef<MapRef | null>(null)

  const [viewState, setViewState] = useState({ ...startLocation, zoom: 17 })

  const [debouncedZoom] = useDebounceValue(viewState.zoom, 500)
  const [debouncedCenter] = useDebounceValue(
    { latitude: startLocation.latitude, longitude: startLocation.longitude },
    50
  )

  const [debouncedBounds] = useDebounceValue(
    getOptimileBounds(mapRef.current?.getBounds()?.toArray().flat()),
    500
  )

  const [filters, setFilters] = useState<MapFiltersType>({
    // TODO enable this when the optimile API gets fixed
    // maxPrice: 5000,
    speed: 'all',
    onlyAvailable: 'false',
  })
  const [detailInfo, setDetailInfo] = useState<ChargingLocation | null>(null)
  const [searchLocation, setSearchLocation] = useState<{
    longitude: number
    latitude: number
  }>()
  const [searchLocationString, setSearchLocationString] = useState('')
  const [showBackdrop, setShowBackdrop] = useState(false)

  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()

  const shouldFlyToAnimate = isDesktop

  const { location, watchLocation } = useGeoLocation({
    onPermissionChange: (permissionGranted) => {
      if (permissionGranted === true) {
        mapRef.current?.flyTo({
          center: { lat: location.latitude, lng: location.longitude },
          zoom: debouncedZoom,
          animate: shouldFlyToAnimate,
        })
      }
    },
  })
  const { top } = useSafeAreaInsets()
  const { trackEvent } = useTracking()
  const theme = useTheme()

  // -- Data --
  const { locations, clusters, loading } = useChargerLocations(
    startLocation,
    debouncedCenter,
    Math.round(debouncedZoom),
    debouncedBounds,
    filters
  )

  // -- Effects --
  useEffect(() => {
    watchLocation()
  }, [])

  // Close the detail info when the user presses the escape key
  useKey(
    'Escape',
    () => {
      handleClose()
    },
    { event: 'keydown' }
  )

  // -- Vars --
  // Use a modified IconClusterLayer to add our own clustering when zoomed in enough
  const locationsLayer = new IconClusterLayer({
    data: locations,
    pickable: true,
    iconAtlas,
    iconMapping: iconMapping as unknown as string,
    id: 'icon-cluster',
    sizeScale: 40,
    selectedId: detailInfo?.id,
  }) as unknown as Layer

  // Use a basic IconLayer to show the optimile clusters
  const clustersLayer = new IconLayer({
    id: 'icon-layer',
    data: clusters,
    pickable: true,
    getPosition: (d) => [d.longitude, d.latitude],
    sizeScale: 30,
    iconAtlas,
    iconMapping,
    getIcon: (d) => getIconName(d.number_of_locations, d.status),
    getSize: (d) => {
      if (d.number_of_locations === 1) return 0
      return Math.min(100, d.number_of_locations) / 100 + 1
    },
  })

  // Choose the right layer based on zoom level
  const layers = [debouncedZoom > 11 ? [locationsLayer] : [clustersLayer]]

  // -- Handlers --
  const handleClick = (
    info: PickingInfo<
      (ChargingLocation & ClusterProperties) | OptimileChargingPointCluster,
      { expansionZoom: number }
    >
  ) => {
    if (
      info.object &&
      (info.object as OptimileChargingPointCluster)?.number_of_locations
    ) {
      // This is a optimile cluster
      // We don't have the expansion zoom available here, so we just zoom in a bit
      return mapRef.current?.flyTo({
        center: { lat: info.object.latitude, lng: info.object.longitude },
        zoom: debouncedZoom + 2,
        animate: shouldFlyToAnimate,
      })
    }

    if (info.object && !(info.object as ClusterProperties).cluster) {
      // This is a marker with a single location
      trackEvent(EventType.Click, 'select_charging_point')

      const currentZoom = mapRef.current?.getZoom() || CHARGER_ZOOM_LEVEL
      const zoom =
        currentZoom > CHARGER_ZOOM_LEVEL ? currentZoom : CHARGER_ZOOM_LEVEL

      mapRef.current?.flyTo({
        center: { lat: info.object.latitude, lng: info.object.longitude },
        zoom,
        animate: shouldFlyToAnimate,
      })

      setDetailInfo(info.object as ChargingLocation)
    }

    if (info.object && (info.object as ClusterProperties).cluster) {
      // This is our own cluster
      trackEvent(EventType.Click, 'select_charging_cluster')

      // Supercluster provides the expansion zoom, we use it to zoom in until the cluster is expanded into multiple markers / clusters
      mapRef.current?.flyTo({
        center: { lat: info.coordinate![1], lng: info.coordinate![0] },
        zoom: info.expansionZoom,
        animate: shouldFlyToAnimate,
      })
    }
  }

  // -- Handlers --
  const handleSelectAddress = (address: MapboxAddress | null) => {
    if (!address) return

    trackEvent(EventType.Click, 'search_address')

    setDetailInfo(null)

    if (address.features[0].properties.bbox) {
      mapRef.current?.fitBounds(address.features[0].properties.bbox)
    } else {
      mapRef.current?.flyTo({
        center: {
          lat: address.features[0].geometry.coordinates[1],
          lng: address.features[0].geometry.coordinates[0],
        },
        zoom: 17,
        animate: shouldFlyToAnimate,
      })
    }

    setSearchLocation({
      latitude: address.features[0].geometry.coordinates[1],
      longitude: address.features[0].geometry.coordinates[0],
    })

    const name = address.features[0].properties.name
    const country = address.features[0].properties.context.country?.name || ''
    const addressString = createMapboxAddressString(
      address.features[0].properties.context
    )

    const hasAddress = addressString.trim().length > 0

    setSearchLocationString(
      `${hasAddress ? addressString : name}${
        country === name ? '' : `, ${country}`
      }`
    )
  }

  const handleClose = () => {
    setDetailInfo(null)
    trackEvent(EventType.Click, 'close_charging_point')
  }

  return (
    <>
      <StMapContainer>
        <MapBox
          ref={mapRef}
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
          {...viewState}
          onMove={(viewStateChangeEvent) =>
            setViewState(viewStateChangeEvent.viewState)
          }
          style={{
            width: '100%',
            height: isDesktop
              ? 'calc(100vh - var(--navigation-height))'
              : 'calc(100vh - (var(--bottom-navigation-height) + var(--inset-bottom, 20px) - 20px))',
          }}
          dragRotate={false}
          touchPitch={false}
          pitchWithRotate={false}
        >
          <DeckGLOverlay
            interleaved
            layers={layers}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onClick={handleClick}
            getCursor={({ isHovering }) => (isHovering ? 'pointer' : 'grab')}
          />

          <Marker latitude={location.latitude} longitude={location.longitude}>
            <StCurrentLocation />
          </Marker>

          {searchLocation ? (
            <Marker
              anchor="bottom"
              latitude={searchLocation.latitude}
              longitude={searchLocation.longitude}
            >
              <FontAwesomeIcon
                icon={['fass', 'location-dot']}
                fontSize={30}
                color={theme.theme.colors['senary-1']}
              />
            </Marker>
          ) : null}

          {loading && isDesktop && (
            <StWebLoader src="/assets/map-loader-web.lottie" autoplay loop />
          )}
          {loading && !isDesktop && !detailInfo && (
            <StMobileLoader
              src="/assets/map-loader-mobile.lottie"
              autoplay
              loop
            />
          )}
        </MapBox>
      </StMapContainer>

      <StContentContainer>
        <StsearchBoxContainer>
          <AddressSearch
            name="addressSearch"
            showIcon
            clearable
            sharp={false}
            displayValue={searchLocationString}
            onSelect={handleSelectAddress}
            placeholder={t('map.search.placeholder')}
            location={startLocation}
            types={['address', 'city', 'country', 'postcode', 'street']}
          />
          {((!detailInfo && isDesktop) || !isDesktop) && (
            <MapFilters onSubmit={setFilters} values={filters} />
          )}
        </StsearchBoxContainer>

        {detailInfo && isDesktop && (
          <StCard>
            <AuthenticatedChargeLocationDetail
              detailInfo={detailInfo}
              onClose={handleClose}
            />
          </StCard>
        )}

        {!isDesktop && (
          <BottomSheet
            isOpen={!!detailInfo}
            onClose={() => setDetailInfo(null)}
            title={detailInfo?.name}
            snapPoints={
              Capacitor.isNativePlatform()
                ? [-(34 + top), 200, 0]
                : [-34, 200, 0]
            }
            onSnap={(index) => {
              setShowBackdrop(index === 0)
            }}
            initialSnap={1}
            hasBackdrop={showBackdrop}
            loading={loading}
          >
            {detailInfo && (
              <AuthenticatedChargeLocationDetail
                detailInfo={detailInfo}
                onClose={handleClose}
              />
            )}
          </BottomSheet>
        )}
      </StContentContainer>
    </>
  )
}

export const StMapContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;

  overflow: hidden;
`

export const StCurrentLocation = styled.div`
  width: 20px;
  aspect-ratio: 1 /1;

  background-color: #0038ff;

  border-radius: 999px;
  border: 3px solid ${({ theme }) => theme.theme.colors.white};
  box-shadow: 0px 0px 15px rgba(0, 102, 255, 0.5);
`

const StContentContainer = styled.div`
  pointer-events: none;

  margin: 0 auto;

  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space10});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  position: absolute;
  top: calc(
    ${({ theme }) => theme.UI.SpacingPx.Space4} + var(--inset-top, 0px)
  );
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-auto-rows: 100%;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  & > div:only-child {
    grid-column: 1 / -1;
  }

  & > div {
    pointer-events: auto;
  }

  @media ${breakpoints.desktop} {
    top: calc(
      var(--navigation-height) + ${({ theme }) => theme.UI.SpacingPx.Space10}
    );

    max-height: calc(
      100% - ${({ theme }) => theme.UI.SpacingPx.Space26} -
        var(--navigation-height)
    );
  }
`

// This converts the mapbox bounds to a string that optimile can understand (long, lat are the other way around)
function getOptimileBounds(bounds: number[] | undefined) {
  if (!bounds) {
    return ''
  }

  return [bounds[1], bounds[0], bounds[3], bounds[2]].join(',')
}

export const StCard = styled.div`
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;
  background-color: ${({ theme }) =>
    theme.components['card-select']['default-bg']};
  border: 1px solid
    ${({ theme }) => theme.components['card-select']['default-border']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};

  overflow: scroll;

  height: fit-content;
  max-height: 100%;

  // remove scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const StWebLoader = styled(DotLottiePlayer)`
  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space5};
  left: 50%;
  transform: translateX(-50%);

  width: 70px;
`

export const StMobileLoader = styled(DotLottiePlayer)`
  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  left: 50%;
  transform: translateX(-50%);

  width: 40px;
`

const StsearchBoxContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  width: 100%;
`
