import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { FilterTypes } from '../../../components/filters/Filters'
import { ContentContainer } from '../../../components/page-layout'
import { H4 } from '../../../components/typography'
import { useDownloadZohoFile } from '../../../core/hooks/useDownloadZohoFile'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { usePermissions } from '../../hooks/usePermissions'

import type { Filter } from '../../../components/filters/Filters'
import type { Column, BaseRow } from '../../../dataTable/components/DataTable'

export enum DocumentType {
  Nota = 'Nota',
  UsageDetails = 'Usage Details',
  Offer = 'Offer',
}

export enum DocumentFileType {
  PDF = 'PDF',
  DOCX = 'DOCX',
  DOC = 'DOC',
  TXT = 'TXT',
  XLSX = 'XLSX',
  CSV = 'CSV',
  XLS = 'XLS',
  PPTX = 'PPTX',
  PPT = 'PPT',
}

// Add interface for document type
type Document = BaseRow & {
  id: string
  name: string
  type: DocumentType
  fileType: DocumentFileType
  fileName?: string
  fileId?: string
  added: string
  isRead: boolean
}

export const DocumentsDataTable = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { fetchDataAsync } = useDataTable()
  const { hasPermission } = usePermissions()
  const { download } = useDownloadZohoFile()

  const [, executeMarkAsRead] = useAuthMemberAxios({}, { manual: true })

  const handleDownload = useCallback(
    async (document: Document) => {
      const fileName = document.fileName || ''
      const fileType = fileName.split('.').pop()?.toLowerCase()

      try {
        // Download the file
        await download(document.name, document.fileId as string, true, fileType)

        // Mark as read after successful download
        await executeMarkAsRead({
          url: Urls.markDocumentAsRead.replace(':id', document.id),
          method: 'POST',
        })

        // Refresh the data to update the UI
        await fetchDataAsync(1)
      } catch (error) {
        console.error('Failed to handle document download:', error)
      }
    },
    [download, executeMarkAsRead, fetchDataAsync]
  )

  // Create filter options for document types
  const typeFilterOptions = useMemo(() => {
    return [
      {
        key: DocumentType.Offer,
        label: t('employer.documents.type.offer'),
      },
      ...(hasPermission('SHOW_DOCUMENTS')
        ? [
            {
              key: DocumentType.Nota,
              label: t('employer.documents.type.nota'),
            },
          ]
        : []),
      ...(hasPermission('VIEW_USAGE_DATA')
        ? [
            {
              key: DocumentType.UsageDetails,
              label: t('employer.documents.type.usage-details'),
            },
          ]
        : []),
    ]
  }, [t, hasPermission])

  const columns = useMemo<Column<Document>[]>(
    () => [
      {
        key: 'isRead',
        label: '',
        type: ColumnType.STRING,
        width: '40px',
        transformData: (row) => (
          <UnreadCell>{!row.isRead && <NotificationDot />}</UnreadCell>
        ),
      },
      {
        key: 'name',
        label: t('employer.documents.name'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (row) => row.name,
        bold: (row) => !row.isRead,
      },
      {
        key: 'createdAt',
        label: t('employer.documents.date'),
        type: ColumnType.DATE,
        sortable: true,
        transformData: (row) => row.createdAt?.toString(),
        bold: (row) => !row.isRead,
      },
      {
        key: 'type',
        label: t('employer.documents.type'),
        type: ColumnType.CHIP,
        sortable: true,
        transformData: (row) => row.type,
        bold: (row) => !row.isRead,
      },
      {
        key: 'fileType',
        label: t('employer.documents.fileType'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (row) => {
          const fileName = row.fileName?.toString() || ''
          return fileName.split('.').pop()?.toUpperCase() ?? ''
        },
        bold: (row) => !row.isRead,
      },
      {
        key: 'download',
        label: '',
        type: ColumnType.ACTION,
        icon: ['fasr', 'file-download'],
        onClick: (document) => handleDownload(document),
      },
    ],
    [handleDownload, t]
  )

  const filters = useMemo<Filter[]>(() => {
    return [
      {
        key: 'type',
        placeholder: t('employer.documents.type'),
        type: FilterTypes.Multi,
        options: typeFilterOptions,
      },
      {
        key: 'date',
        placeholder: t('employer.documents.date'),
        type: FilterTypes.DateRange,
      },
    ]
  }, [typeFilterOptions, t])

  const handleRowClick = useCallback(
    (row: Document) => {
      handleDownload(row)
    },
    [handleDownload]
  )

  // -- Render --
  return (
    <ContentContainer>
      <StContainer>
        <StHeader>
          <StTitle>{t('employer.documents.title')}</StTitle>
        </StHeader>

        <StyledTableContainer>
          <DataTable<Document>
            columns={columns}
            filters={filters}
            onRowClick={handleRowClick}
            emptyTitle={t('employer.documents.empty.title')}
            emptyDescription={t('employer.documents.empty.description')}
            emptyFiltersTitle={t('employer.documents.empty.filters.title')}
            emptyFiltersDescription={t(
              'employer.documents.empty.filters.description'
            )}
          />
        </StyledTableContainer>
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding-bottom: 70px;
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const NotificationDot = styled.span.attrs({ className: 'notification-dot' })`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.theme.colors['quaternary-1']};
  border-radius: 50%;
  display: inline-block;
`

const UnreadCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StyledTableContainer = styled.div`
  table {
    tbody tr {
      background-color: transparent;
      transition: background-color 0.2s ease;
    }

    tbody tr td:first-child {
      padding: 0;
    }

    tbody tr .notification-dot {
      ~ td {
        background-color: ${({ theme }) => theme.theme.colors['nonary-10']};
      }
    }
  }
`
