import { styled } from 'styled-components'

import { Loader } from '../../components/general/Loader'
import { Filters } from '../../employee/onboarding/components/Filters'
import { useDataTable } from '../hooks/useDataTable'
import { PaginationType } from '../providers/DataTableProvider'

import { DataTableBody } from './DataTableBody'
import { DataTableFooter } from './DataTableFooter'
import { DataTableHeader } from './DataTableHeader'

import type { Filter } from '../../components/filters/Filters'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ElementType, ReactNode } from 'react'

export type BaseRow = {
  id: string
  [key: string]: unknown | Omit<BaseRow, 'id'>
}

export type Column<T> = {
  key: string
  label?: string
  sortable?: boolean
  type: ColumnType
  transformData?: (data: T) => string | null | undefined | ReactNode
  prepend?: string
  append?: string
  decimal?: number
  icon?: IconProp
  onClick?: (data: T) => void
  disabled?: boolean
  component?: ElementType
  bold?: boolean | ((data: T) => boolean)
}

export enum ColumnType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  ACTION = 'action',
  CHIP = 'chip',
}

type DataTableProps<T> = {
  columns: Column<T>[]
  filters?: Filter[]
  emptyTitle: string
  emptyDescription: string
  emptyFiltersTitle: string
  emptyFiltersDescription: string
  emptyImage?: string
}

type HrefDataTableProps<T> = DataTableProps<T> & {
  hrefGenerator?: (dataRow: T) => string
  onRowClick?: never
}

type ClickHandlerDataTableProps<T> = DataTableProps<T> & {
  onRowClick?: (dataRow: T) => void
  hrefGenerator?: never
}

export function DataTable<T extends BaseRow>({
  columns,
  onRowClick,
  hrefGenerator,
  filters,
  emptyTitle,
  emptyDescription,
  emptyFiltersTitle,
  emptyFiltersDescription,
  emptyImage,
}: ClickHandlerDataTableProps<T> | HrefDataTableProps<T>) {
  // Hooks
  const {
    filterValues,
    setFilterValues,
    clearFilters,
    navigationContainerBottom,
    // Data
    tableData,
    loading,
    intersectionRef,
    initialLoading,
    paginationType,
  } = useDataTable()

  return (
    <StContainer>
      {filters && (
        <Filters
          filters={filters}
          filterValues={filterValues}
          withSearch
          onFiltersChange={(filterKey, value) =>
            setFilterValues({
              ...filterValues,
              [filterKey]: value,
            })
          }
          onClearFilters={() => {
            clearFilters()
          }}
          loading={initialLoading && !tableData}
          stickyPosition={navigationContainerBottom}
        />
      )}
      <div>
        <StTable>
          <DataTableHeader<T>
            empty={!tableData || tableData.meta.filteredItemCount === 0}
            columns={columns}
            loading={initialLoading && !tableData}
            hasFilters={!!filters?.length}
          />
          <DataTableBody<T>
            columns={columns}
            data={tableData?.data as T[]}
            hasFilters={!!filters?.length}
            loading={
              initialLoading ||
              (loading && paginationType === PaginationType.Pagination)
            }
            onRowClick={onRowClick}
            hrefGenerator={hrefGenerator}
            emptyTitle={emptyTitle}
            emptyDescription={emptyDescription}
            emptyFiltersTitle={emptyFiltersTitle}
            emptyFiltersDescription={emptyFiltersDescription}
            emptyImage={emptyImage}
          />
          {paginationType === PaginationType.Pagination && (
            <DataTableFooter<T>
              metaData={tableData?.meta}
              loading={
                initialLoading ||
                (loading && (!tableData || tableData.meta.itemCount === 0))
              }
            />
          )}
        </StTable>
      </div>
      {paginationType === PaginationType.Infinite && (
        <StIntersect ref={intersectionRef} />
      )}
      {loading && paginationType === PaginationType.Infinite && tableData && (
        <StLoaderContainer>
          <Loader size={85} />
        </StLoaderContainer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const StTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  position: relative;
  z-index: 1;
`
const StIntersect = styled.div`
  height: 1px;
  width: 100%;

  position: absolute;
  bottom: 40vh;
`

const StLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`
