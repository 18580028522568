import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployeeRoutes } from '../../../../routing/routes'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import {
  InformationOverview,
  InformationOverviewType,
} from '../../../components/general/InformationOverview'
import { useUser } from '../../../user/hooks/useUser'
import { FloatingContainerButton } from '../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../components/OnboardingStepTitle'
import { useOnboarding } from '../hooks/useOnboarding'
import { formatAddress } from '../util/address'
import { getColorName, getConnectionMethodName } from '../util/product'

import { UseReimbursementsKeys } from './account/UseReimbursementsStep'
import { CableDigWorkKeys } from './preferences/CableDigWorksStep'
import { cableDistanceOptions } from './preferences/CableDistanceStep'
import { ChargerDisassembleKeys } from './preferences/ChargerDisassembleStep'
import { DigitalMeterKeys } from './preferences/DigitalMeterStep'
import { InstallationMethodKeys } from './preferences/InstallationMethodStep'

import type { InformationItem } from '../../../components/general/InformationOverviewItem'

export const OnboardingOverviewStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const {
    setLoading,
    values: onboardingValues,
    products,
    items: {
      chargerLocationItems,
      privateTerrainItems,
      wallMaterialItems,
      gridConnectionOptions,
      amperageOptions,
    },
    selfEmployedFlow,
  } = useOnboarding()
  const { user, refetch } = useUser()
  const navigate = useNavigate()

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.FinalizeOnboarding,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  // -- Handlers --
  const handleSubmit = async () => {
    try {
      setLoading(true)

      // The finalize api call will request msp card if needed and update hcp status
      await execute()

      // Refetch user to get the latest statuses
      await refetch()

      navigate(EmployeeRoutes.Root)
    } catch {
      setLoading(false)
      return console.error('Failed to finalize onboarding')
    }
  }

  // -- Personal Information --
  const personalInformationValues: InformationItem[] = [
    {
      title: t('onboarding.account.firstName'),
      content: user.firstName,
    },
    {
      title: t('onboarding.account.lastName'),
      content: user.lastName,
    },
    {
      title: t('onboarding.account.address'),
      content: formatAddress(user.addresses[0]),
    },
    {
      title: t('onboarding.overview.email'),
      content: user.email,
    },
    {
      title: t('onboarding.account.tel'),
      content: user.tel,
    },
    {
      title: t('onboarding.overview.iban'),
      content:
        selfEmployedFlow &&
        onboardingValues.useReimbursementsKey === UseReimbursementsKeys.No
          ? undefined
          : onboardingValues.bankDetails.iban,
    },
  ]

  // -- HCP Information --
  const selectedChargerLocation = chargerLocationItems.find(
    (item) => item.key === onboardingValues.chargerLocationKey
  )

  const selectedPrivateTerrainItem = privateTerrainItems.find(
    (item) => item.key === onboardingValues.privateTerrainKey
  )

  const selectedProduct = products.data?.find(
    (product) => product.id === onboardingValues.product
  )
  const selectedProductColor = selectedProduct?.colors.find(
    (color) => color.id === onboardingValues.color
  )
  const selectedMountingOption = selectedProduct?.mountingOptions.find(
    (option) => option.type === onboardingValues.installationMethodKey
  )?.type
  const selectedConnectionMethod = selectedProduct?.connectionMethods.find(
    (method) => method.id === onboardingValues.connectionMethod
  )

  const formattedProductString = [
    selectedProduct?.productName,
    selectedProductColor
      ? getColorName(selectedProductColor, user.language)
      : null,
    selectedMountingOption === InstallationMethodKeys.Pole
      ? t('onboarding.overview.pole-mount')
      : t('onboarding.overview.wall-mount'),
    selectedConnectionMethod
      ? getConnectionMethodName(selectedConnectionMethod, user.language)
      : null,
  ]
    .filter(Boolean)
    .join(', ')

  const selectedWallMaterial = wallMaterialItems.find(
    (item) => item.key === onboardingValues.installationMaterialKey
  )

  const hcpInformationValues: InformationItem[] = [
    {
      title: t('onboarding.location.title'),
      content: selectedChargerLocation?.name,
      testId: 'location',
    },
    {
      title: t('onboarding.private-terrain.title'),
      content: selectedPrivateTerrainItem?.name,
      testId: 'privateTerrain',
    },
    {
      title: t('onboarding.overview.hcp-type'),
      content: formattedProductString,
      testId: 'hcpType',
    },
    selectedWallMaterial?.name
      ? {
          title: t('onboarding.installation-material.title'),
          content: selectedWallMaterial?.name,
          testId: 'installationMaterial',
        }
      : undefined,
  ].filter(Boolean) as InformationItem[]

  // -- Technical Information --
  const selectedCableDistance = cableDistanceOptions.find(
    (item) => item.key === onboardingValues.cableLengthKey
  )

  const selectedGridConnection = gridConnectionOptions.find(
    (item) => item.key === onboardingValues.connectionType.gridConnection
  )

  const selectedAmperage = amperageOptions.find(
    (item) => item.key === onboardingValues.connectionType.amperage
  )

  const technicalInformationValues: InformationItem[] = [
    {
      title: t('onboarding.cable-length.title'),
      content: selectedCableDistance
        ? t(selectedCableDistance.translationKey)
        : null,
      testId: 'cableLength',
    },
    {
      title: t('onboarding.dig-work.title'),
      content:
        onboardingValues.digWorkKey === CableDigWorkKeys.Yes
          ? t('onboarding.dig-work.yes.title')
          : t('onboarding.dig-work.no.title'),
      testId: 'digWork',
    },
    {
      title: t('onboarding.digital-meter.title'),
      content:
        onboardingValues.digitalMeterKey === DigitalMeterKeys.Yes
          ? t('onboarding.digital-meter.yes.title')
          : t('onboarding.digital-meter.no.title'),
      testId: 'digitalMeter',
    },
    {
      title: t('onboarding.connection-type.grid-connection.title'),
      content: selectedGridConnection?.label,
      testId: 'gridConnection',
    },
    {
      title: t('onboarding.connection-type.ampere'),
      content: selectedAmperage?.label,
      testId: 'amperage',
    },
    {
      title: t('onboarding.cable-route.label'),
      content: onboardingValues.cableRoute,
      testId: 'cableRoute',
    },
    {
      title: t('onboarding.charger-disassemble.title'),
      content:
        onboardingValues.chargerDisassembleKey === ChargerDisassembleKeys.Yes
          ? t('onboarding.charger-disassemble.yes.title')
          : t('onboarding.charger-disassemble.no.title'),
      testId: 'chargerDisassemble',
    },
    {
      title: t('onboarding.extra-comment.title'),
      content:
        !!onboardingValues.extraComment && onboardingValues.extraComment !== ''
          ? onboardingValues.extraComment
          : null,
      testId: 'extraComment',
    },
  ]

  // -- Images --
  const imageValues: InformationItem[] = [
    {
      title: t('onboarding.desktop-documents.input-1.label'),
      images: onboardingValues.electricalBoxImageIds,
    },
    {
      title: t('onboarding.desktop-documents.input-2.label'),
      images: onboardingValues.fuseBoxImageIds,
    },
    {
      title: t('onboarding.desktop-documents.input-3.label'),
      images: onboardingValues.electricityMeterImageIds,
    },
    {
      title: t('onboarding.desktop-documents.input-4.label'),
      images: onboardingValues.chargerLocationImageIds,
    },
    onboardingValues.extraImageIds?.length
      ? {
          title: t('onboarding.desktop-documents.input-5.label'),
          images: onboardingValues.extraImageIds,
        }
      : undefined,
  ].filter(Boolean) as InformationItem[]

  // -- Render --
  return (
    <>
      <OnboardingStepTitle>
        {t('onboarding.overview.title')}
      </OnboardingStepTitle>

      <StInformationContainer>
        <InformationOverview
          title={t('onboarding.overview.personal-information')}
          values={personalInformationValues}
        />
        <InformationOverview
          title={t('onboarding.overview.location-hcp')}
          values={hcpInformationValues}
        />
        <InformationOverview
          title={t('onboarding.overview.technical-information')}
          values={technicalInformationValues}
        />
        <InformationOverview
          type={InformationOverviewType.ZohoImages}
          title={t('onboarding.overview.uploaded-images')}
          values={imageValues}
        />
      </StInformationContainer>

      <FloatingContainerButton
        onClick={handleSubmit}
        title={t('onboarding.overview.submit')}
      />
    </>
  )
}

const StInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin-bottom: ${({ theme }) =>
    `calc(var(--sticky-button-container-height) + ${theme.UI.SpacingPx.Space6})`};
`
