import { useTranslation } from 'react-i18next'

import { Fractions } from '../../../components/statistics/Fractions'
import { doubleBoltIcon } from '../../../core/icons/DoubleBolt'
import {
  formatPowerConsumption,
  statisticsChargingSpeedData,
} from '../../utils/statistics'

import type { ChargingSessionStatistics } from '../types/types'

type ChargingSpeedFractionsProps = {
  statistics?: ChargingSessionStatistics | null
}

export const ChargingSpeedFractions = ({
  statistics,
}: ChargingSpeedFractionsProps) => {
  const { t } = useTranslation()

  const { acUsagePercentage, dcUsagePercentage } =
    statisticsChargingSpeedData(statistics)

  return (
    <Fractions
      data={[
        {
          title: t('employer.usage.chargingSpeedFractions.standard'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerPowerType?.AC ?? 0
          ),
          subText: t('employer.usage.chargingSpeedFractions.standard.subtext', {
            percentage: acUsagePercentage.toFixed(0),
            consumption: formatPowerConsumption(
              statistics?.totalUsagePerType.Public ?? 0,
              0
            ),
          }),
          width: acUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: '#CBE6F6',
          icon: ['fass', 'bolt'],
        },
        {
          title: t('employer.usage.chargingSpeedFractions.fast'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerPowerType?.DC ?? 0
          ),
          subText: t('employer.usage.chargingSpeedFractions.fast.subtext', {
            percentage: dcUsagePercentage.toFixed(0),
            consumption: formatPowerConsumption(
              statistics?.totalUsagePerType.Public ?? 0,
              0
            ),
          }),
          width: dcUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: '#E4F2FA',
          icon: doubleBoltIcon,
        },
      ]}
      disabled={!statistics?.totalUsage}
      disabledMessage={t('employer.chargingSessions.stats.no-data')}
    />
  )
}
