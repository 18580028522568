import { useTranslation } from 'react-i18next'

import { Fractions } from '../../../components/statistics/Fractions'
import {
  formatPowerConsumption,
  statisticsChargingTypeData,
} from '../../utils/statistics'

import type { ChargingSessionStatistics } from '../types/types'

type UsageFractionsProps = {
  statistics?: ChargingSessionStatistics | null
}

export const UsageFractions = ({ statistics }: UsageFractionsProps) => {
  const { t } = useTranslation()

  const { homeUsagePercentage, publicUsagePercentage, workUsagePercentage } =
    statisticsChargingTypeData(statistics)

  return (
    <Fractions
      data={[
        {
          title: t('employer.usage.usageFractions.home'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerType?.Home ?? 0
          ),
          subText: t('employer.usage.usageFractions.home.subtext', {
            percentage: homeUsagePercentage.toFixed(0),
            consumption: formatPowerConsumption(statistics?.totalUsage ?? 0, 0),
          }),
          width: homeUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: '#E9F877',
          icon: ['fass', 'home'],
        },
        {
          title: t('employer.usage.usageFractions.work'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerType?.Work ?? 0
          ),
          subText: t('employer.usage.usageFractions.work.subtext', {
            percentage: workUsagePercentage.toFixed(0),
            consumption: formatPowerConsumption(statistics?.totalUsage ?? 0, 0),
          }),
          width: workUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: '#F2FBB1',
          icon: ['fass', 'car-building'],
        },
        {
          title: t('employer.usage.usageFractions.public'),
          formattedAmount: formatPowerConsumption(
            statistics?.totalUsagePerType?.Public ?? 0
          ),
          subText: t('employer.usage.usageFractions.public.subtext', {
            percentage: publicUsagePercentage.toFixed(0),
            consumption: formatPowerConsumption(statistics?.totalUsage ?? 0, 0),
          }),
          width: publicUsagePercentage,
          total: statistics?.totalUsage ?? 0,
          backgroundColor: '#F9FDD8',
          icon: ['fass', 'road'],
        },
      ]}
      disabled={!statistics?.totalUsage}
      disabledMessage={t('employer.chargingSessions.stats.no-data')}
    />
  )
}
