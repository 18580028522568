import { useState, createContext, useEffect, useRef } from 'react'
import { useIntersectionObserver, useUpdateEffect } from 'usehooks-ts'

import { useAuthMemberAxios } from '../../api/hooks/useAuthMemberAxios'
import { useMember } from '../../member/hooks/useMember'
import { SortKeys } from '../components/DataTableHeader'

import type { BaseRow } from '../components/DataTable'
import type { SortInfo } from '../components/DataTableHeader'
import type { PaginationObject } from '../types/Types'
import type {
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  RefObject,
} from 'react'

export const ITEMS_PER_PAGE = 10

export enum PaginationType {
  None = 'none',
  Infinite = 'infinite',
  Pagination = 'pagination',
}

export type DataTableContext<
  Statistics extends Record<string, unknown> = Record<string, unknown>
> = {
  dataCount: {
    itemCount: number
    filteredItemCount: number
  }
  setDataCount: (dataCount: {
    itemCount: number
    filteredItemCount: number
  }) => void
  statistics: Statistics | null
  setStatistics: Dispatch<SetStateAction<Statistics | null>>
  filterValues: Record<string, unknown>
  clearFilters: () => void
  setFilterValues: Dispatch<SetStateAction<Record<string, unknown>>>
  navigationContainerBottom: number
  tableData?: PaginationObject<BaseRow, Statistics | null>
  loading: boolean
  initialLoading: boolean
  handlePageChange: (pageNumber: number) => void
  fetchDataAsync: (page: number, additive?: boolean) => Promise<void>
  sort?: SortInfo
  setSort: Dispatch<SetStateAction<SortInfo | undefined>>
  intersectionRef: RefObject<HTMLDivElement>
  paginationType: PaginationType
  amount: number
}

export const dataTableContext = createContext<DataTableContext<
  Record<string, unknown>
> | null>(null)

type DataTableContextProps = PropsWithChildren & {
  initialFilterValues?: Record<string, unknown>
  standaloneFilterKeys?: string[] // Keys that should not be influenced by any table actions. For example the clear filters button
  url: string
  defaultSort?: string
  defaultSortDirection?: SortKeys
  paginationType?: PaginationType
  amount?: number
}

export const DataTableProvider = ({
  initialFilterValues,
  standaloneFilterKeys,
  children,
  url,
  defaultSort,
  defaultSortDirection = SortKeys.ASCENDING,
  paginationType = PaginationType.Pagination,
  amount = ITEMS_PER_PAGE,
}: DataTableContextProps) => {
  const { currentMember } = useMember()

  const [dataCount, setDataCount] = useState<{
    itemCount: number
    filteredItemCount: number
  }>({
    itemCount: 0,
    filteredItemCount: 0,
  })

  const [statistics, setStatistics] = useState<Record<string, unknown> | null>(
    null
  )

  const [filterValues, setFilterValues] = useState<Record<string, unknown>>(
    initialFilterValues ?? {}
  )

  const clearFilters = () => {
    const newFilterValues = { ...filterValues }

    Object.keys(newFilterValues).forEach((key) => {
      if (!standaloneFilterKeys || !standaloneFilterKeys?.includes(key)) {
        delete newFilterValues[key]
      }
    })

    setFilterValues(newFilterValues)
  }

  const navigationContainerBottom =
    document.querySelector('#navigation_container')?.getBoundingClientRect()
      .bottom ?? 0

  // Intersect observer
  const intersectionRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(intersectionRef, {})
  const isVisible = !!entry?.isIntersecting

  // Get the first sortable column
  const [sort, setSort] = useState<SortInfo | undefined>(
    defaultSort
      ? {
          key: defaultSort,
          direction: defaultSortDirection,
        }
      : undefined
  )

  const [initialLoading, setInitialLoading] = useState<boolean>(true)

  // Data fetching
  const [tableData, setTableData] = useState<
    PaginationObject<BaseRow, Record<string, unknown> | null> | undefined
  >(undefined)

  const [{ loading }, refetch] = useAuthMemberAxios<
    PaginationObject<BaseRow, Record<string, unknown> | null>
  >(
    {
      url,
      params: {
        page: 1,
        filters: {
          ...filterValues,
        },
        order: sort?.direction,
        orderByKey: sort?.key,
        take: amount,
      },
    },
    { manual: true }
  )

  const fetchDataAsync = async (page: number, additive = false) => {
    const paginationResponse = await refetch({
      params: {
        page,
        filters: {
          ...filterValues,
        },
        order: sort?.direction,
        orderByKey: sort?.key,
        take: amount,
      },
    })

    if (additive) {
      setTableData((previous) => ({
        ...previous,
        data: [
          ...(previous?.data ?? []),
          ...(paginationResponse.data?.data ?? []),
        ],
        meta: paginationResponse.data?.meta,
        stats: paginationResponse.data?.stats,
      }))
    } else {
      setTableData(paginationResponse.data)
    }

    setInitialLoading(false)
  }

  const handlePageChange = (pageNumber: number) => {
    fetchDataAsync(pageNumber)
  }

  // Fetch more data when the user scrolls to the bottom of the page
  // Also add the data length as a dependency so we check if the intersection observer is triggered meaning we should immediately fetch more data
  useEffect(() => {
    if (
      isVisible &&
      paginationType === PaginationType.Infinite &&
      tableData?.meta.page &&
      tableData.data.length < tableData.meta.filteredItemCount &&
      !loading
    ) {
      fetchDataAsync(tableData.meta.page + 1, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, tableData?.data.length])

  useEffect(() => {
    setInitialLoading(true)
    fetchDataAsync(1)
    // We don't add the refetch function as a dependency because we get an infinite loop otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sort])

  useEffect(() => {
    setDataCount({
      itemCount: tableData?.meta.itemCount ?? 0,
      filteredItemCount: tableData?.meta.filteredItemCount ?? 0,
    })

    const stats = tableData?.stats
    if (stats) {
      setStatistics(stats)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  useUpdateEffect(() => {
    setInitialLoading(true)
    fetchDataAsync(1)
  }, [currentMember.id])

  return (
    <dataTableContext.Provider
      value={{
        dataCount,
        setDataCount,
        statistics,
        setStatistics,
        filterValues,
        clearFilters,
        setFilterValues,
        navigationContainerBottom,
        tableData,
        loading: loading ?? false,
        handlePageChange,
        fetchDataAsync,
        sort,
        setSort,
        intersectionRef,
        initialLoading,
        paginationType,
        amount,
      }}
    >
      {children}
    </dataTableContext.Provider>
  )
}
