import { useLocalStorage } from 'usehooks-ts'

import { LocalStorageKeys } from '../../../config/constants/local-storage-keys'
import { useUser } from '../../user/hooks/useUser'

import type { MspCard } from 'database'
import type { FullMember } from 'types'

type UseMemberReturnType = {
  members: FullMember[]
  currentMember: FullMember
  currentChargeCard?: MspCard & { member: FullMember }
  setCurrentMember: (id: string) => void
}

/** Get or change the current active member */
export const useMember = (): UseMemberReturnType => {
  // -- Hooks --
  const { user } = useUser()

  // -- State --
  const [currentMemberId, setCurrentMemberId] = useLocalStorage(
    LocalStorageKeys.CURRENT_MEMBER,
    user.members[0].id
  )

  // Check if currentMember still exists for the user
  if (
    currentMemberId &&
    !user.members.some((member) => member.id === currentMemberId)
  ) {
    // We can't use the useLocalStorage hook here yet
    localStorage.setItem(
      LocalStorageKeys.CURRENT_MEMBER,
      JSON.stringify(user.members[0].id)
    )
  }

  const currentMember = user.members.find(
    (member) => member.id === currentMemberId
  )

  if (!currentMember) {
    throw new Error('No current member')
  }

  const activeChargeCard = currentMember.mspCards?.find(
    (card) => card.mspCardStatus === 'Active'
  )

  const currentChargeCard =
    // If there is an active charge card, use that
    // If there is no active charge card, use the first cancelled card
    // Cancelled cards are sorted in the backend
    activeChargeCard ?? currentMember.mspCards?.find((card) => card.cancelledAt)

  // Include permissions in the member data
  const fullMembers = user.members.map((member) => ({
    ...member,
    user: user,
    permissions: member.permissions || [],
  }))

  return {
    members: fullMembers,
    currentMember: {
      ...currentMember,
      user: user,
      permissions: currentMember.permissions || [],
    },
    currentChargeCard: currentChargeCard
      ? {
          ...currentChargeCard,
          member: {
            ...currentMember,
            user,
            permissions: currentMember.permissions || [],
          },
        }
      : undefined,
    /** set current member via memberId */
    setCurrentMember: setCurrentMemberId,
  }
}
