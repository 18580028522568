import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'

export const useUnreadDocumentsCount = () => {
  const [{ data: unreadCount, loading }, refetch] = useAuthMemberAxios<number>({
    url: Urls.unreadDocumentsCount,
  })

  return {
    unreadCount: unreadCount ?? 0,
    loading,
    refetch,
  }
}
