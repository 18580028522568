import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { EmployeeRoutes } from '../../../../routing/routes'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import {
  BodySmallRegular,
  BodySmallRegularCss,
  BodySmallSemiBold,
} from '../../../components/typography'
import { useMember } from '../../../member/hooks/useMember'

import { HomeScreenElement } from './HomeScreenElement'
import { MspStatusCard } from './MspStatusCard'

import type { HcpStatus } from 'database'

export type CardContents =
  | {
      bulletPoints: false
      title: string
      description: string
      buttonText?: string
      onClick?: () => void
    }
  | {
      bulletPoints: true
      title: string
      listItems: string[]
    }
  | undefined

type StatusCardProps = {
  hcpStatus: HcpStatus
}

export const StatusCard = ({ hcpStatus }: StatusCardProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMember } = useMember()

  const calculateHcpCardContents = useCallback((): CardContents => {
    let innerHcpStatus = hcpStatus.status

    if (
      currentMember.company.selfEmployed &&
      !currentMember.hcpContractId &&
      !currentMember.mspContractId
    ) {
      // For self employed users, they will only choose a contract during the onboarding
      innerHcpStatus = 'Onboarding'
    }

    switch (innerHcpStatus) {
      case 'Onboarding': {
        return {
          bulletPoints: false,
          title: t(`employee.home.status.${hcpStatus.status}.title`),
          description: t(
            `employee.home.status.${hcpStatus.status}.description`
          ),
          buttonText: t(`employee.home.status.${hcpStatus.status}.button`),
          onClick: () => navigate(EmployeeRoutes.Onboarding),
        }
      }

      case 'OnboardingCompleted':
      case 'SharedWithInstallationPartner':
      case 'PreSiteVisitPlanned': {
        if (hcpStatus.hasExistingDevice) {
          return {
            bulletPoints: false,
            title: t(
              `employee.home.status.OnboardingCompleted.existing-device.title`
            ),
            description: t(
              `employee.home.status.OnboardingCompleted.existing-device.description`
            ),
          }
        }

        return {
          bulletPoints: true,
          title: t('employee.home.status.waitingForQuote.title'),
          listItems: [
            t('employee.home.status.waitingForQuote.bullet-1'),
            t('employee.home.status.waitingForQuote.bullet-3'),
            t('employee.home.status.waitingForQuote.bullet-2'),
          ],
        }
      }

      case 'QuotationApproved': {
        return {
          bulletPoints: false,
          title: t(`employee.home.status.QuotationApproved.title`),
          description: t(`employee.home.status.QuotationApproved.description`),
        }
      }

      case 'OnboardingRejected':
      case 'InstallationPlanned': {
        if (hcpStatus.hasExistingDevice) {
          return {
            bulletPoints: false,
            title: t(
              `employee.home.status.${hcpStatus.status}.existingDevice.title`
            ),
            description: t(
              `employee.home.status.${hcpStatus.status}.existingDevice.description`
            ),
          }
        }

        return {
          bulletPoints: false,
          title: t(`employee.home.status.${hcpStatus.status}.title`),
          description: t(
            `employee.home.status.${hcpStatus.status}.description`
          ),
        }
      }

      case 'QuotationPendingApproval':
      case 'QuotationApprovedByEmployee':
      case 'QuotationApprovedByEmployer': {
        if (hcpStatus.status === 'QuotationApprovedByEmployee') {
          return {
            bulletPoints: false,
            title: t(
              `employee.home.status.QuotationApprovedByEmployee.approved.title`
            ),
            description: t(
              `employee.home.status.QuotationApprovedByEmployee.approved.description`
            ),
          }
        }

        return {
          bulletPoints: false,
          title: t(`employee.home.status.QuotationPendingApproval.title`),
          description: t(
            `employee.home.status.QuotationPendingApproval.description`
          ),
          buttonText: t(`employee.home.status.QuotationPendingApproval.button`),
          onClick: () => navigate(EmployeeRoutes.Quote),
        }
      }

      default: {
        break
      }
    }
  }, [hcpStatus, t, navigate])

  const HcpCardContents = calculateHcpCardContents()

  if (
    !HcpCardContents &&
    ['Active', 'Inapplicable'].includes(currentMember.mspStatus)
  ) {
    return null
  }

  return (
    <HomeScreenElement title={t('employee.home.status')}>
      {HcpCardContents && (
        <StStatusCard>
          <BodySmallSemiBold>{HcpCardContents.title}</BodySmallSemiBold>
          {HcpCardContents.bulletPoints ? (
            <StList>
              {HcpCardContents.listItems.map((item) => (
                <StListItem key={item}>{item}</StListItem>
              ))}
            </StList>
          ) : (
            <>
              <BodySmallRegular>{HcpCardContents.description}</BodySmallRegular>
              {HcpCardContents.buttonText ? (
                <ButtonPrimary
                  compact
                  onClick={HcpCardContents.onClick}
                  size="md"
                >
                  {HcpCardContents.buttonText}
                </ButtonPrimary>
              ) : null}
            </>
          )}
        </StStatusCard>
      )}

      <MspStatusCard />
    </HomeScreenElement>
  )
}

export const StStatusCard = styled.div`
  border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  p {
    margin-bottom: 2px;
  }

  button {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
  }
`

const StList = styled.ul``

const StListItem = styled.li`
  list-style-type: disc;
  list-style-position: inside;

  ${BodySmallRegularCss}

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
