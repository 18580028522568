import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { BodyMediumMediumCss } from '../typography'

import { NavigationItem } from './NavigationItem'
import { NavigationLogo } from './NavigationLogo'

import type { IconName } from '@fortawesome/fontawesome-svg-core'
import type { ReactNode } from 'react'

type NavigationProps = {
  options: NavigationOptions
}

export type NavigationItemType = {
  name: string
  url: string
  icon?: IconName
  badge?: number
}

export type NavigationOptions = {
  logo?: boolean
  navigationItems?: NavigationItemType[]
  rightItem?: ReactNode
  backgroundColor?: string
  onGoBack?: () => void
}

export const Navigation = ({ options }: NavigationProps) => {
  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()

  // -- Render --
  return (
    <StNavigationContainer
      id="navigation_container"
      $backgroundColor={options.backgroundColor}
    >
      <StNavigationContent>
        <StNavigationSide>
          {options.onGoBack ? (
            <StNavButton onClick={options.onGoBack}>
              <FontAwesomeIcon icon={['fasr', 'arrow-left']} />
              {isDesktop ? t('navigation.back') : null}
            </StNavButton>
          ) : options.logo ? (
            <NavigationLogo />
          ) : null}
        </StNavigationSide>
        <StMiddleContainer>
          {options.navigationItems && options.navigationItems.length > 0
            ? options.navigationItems.map((item) => {
                return (
                  <NavigationItem
                    key={item.name}
                    to={item.url}
                    name={item.name}
                    badge={item.badge}
                  />
                )
              })
            : null}
        </StMiddleContainer>
        <StNavigationSide>{options.rightItem ?? null}</StNavigationSide>
      </StNavigationContent>
    </StNavigationContainer>
  )
}

export const StNavigationContainer = styled.div<{ $backgroundColor?: string }>`
  position: sticky;
  top: 0;

  height: calc(var(--navigation-height) + var(--inset-top, 0px));
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.components.menu.default.bg};
  width: 100%;

  border: 1px solid
    ${({ theme, $backgroundColor }) =>
      $backgroundColor ?? theme.components.menu.default.border};

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space7};

  z-index: 500;
`

export const StNavigationContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  margin: 0 auto;

  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
`

const StMiddleContainer = styled.div`
  height: 100%;

  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  margin: 0 ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StNavigationSide = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

const StNavButton = styled.button`
  ${BodyMediumMediumCss}
  color: ${({ theme }) => theme.components.menu.wizard['item-text']};

  height: 100%;

  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  cursor: pointer;
`
