import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { formatDateRange } from 'utils/src'

import { replaceParametersInPath } from '../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../routing/routes'
import { EventType, useTracking } from '../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../api/urls'
import { ButtonSecondary } from '../../../components/button/ButtonSecondary'
import { FilterTypes } from '../../../components/filters/Filters'
import { StatisticCard } from '../../../components/general/StatisticCard'
import { ContentContainer } from '../../../components/page-layout'
import { BodyExtraSmallRegular, H4 } from '../../../components/typography'
import { useGetDateFnsLocale } from '../../../core/hooks/useGetDateFnsLocale'
import { downloadBlob } from '../../../core/lib/downloadBlob'
import { ColumnType, DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { Filters } from '../../../employee/onboarding/components/Filters'
import { formatCurrency, formatPowerConsumption } from '../../utils/statistics'
import { type ChargingSessionStatistics } from '../types/types'

import { ChargingSpeedFractions } from './ChargingSpeedFractions'
import { CostPerTypeFractions } from './CostPerTypeFractions'
import { TotalCostBreakdown } from './TotalCostBreakdown'
import { UsageFractions } from './UsageFractions'
import { UsageStatisticsLoader } from './UsageStatisticsLoader'

import type { Filter } from '../../../components/filters/Filters'
import type { Column } from '../../../dataTable/components/DataTable'
import type { MemberForUsageReport } from 'types'

export const UsageDataTable = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dateFnsLocale = useGetDateFnsLocale()

  const {
    initialLoading,
    tableData,
    navigationContainerBottom,
    statistics,
    filterValues,
    setFilterValues,
    clearFilters,
  } = useDataTable<ChargingSessionStatistics>()

  // State
  const [exportLoading, setExportLoading] = useState(false)

  // -- Vars --
  const columns = useMemo<Column<MemberForUsageReport>[]>(
    () => [
      {
        key: 'fullName',
        label: t('employer.usage.table.employee'),
        type: ColumnType.STRING,
        sortable: true,
      },
      {
        key: 'chargedAbroad',
        label: t('employer.usage.table.region'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (member) => {
          return member.chargedAbroad
            ? t('employer.usage.table.region.europe')
            : t('employer.usage.table.region.belgium')
        },
      },
      {
        key: 'hasCharger',
        label: t('employer.usage.table.homeCharger'),
        type: ColumnType.STRING,
        sortable: true,
        transformData: (member) => {
          return member.hasCharger
            ? t('employer.usage.table.homeCharger.yes')
            : t('employer.usage.table.homeCharger.no')
        },
      },
      {
        key: 'totalKwh',
        label: t('employer.usage.table.consumption'),
        type: ColumnType.NUMBER,
        sortable: true,
        transformData: (member) => {
          return formatPowerConsumption(member.totalKwh)
        },
      },
      {
        key: 'costPerKwh',
        label: t('employer.usage.table.costPerKwh'),
        type: ColumnType.NUMBER,
        sortable: true,
        transformData: (member) => {
          return formatCurrency(member.costPerKwh)
        },
      },
      {
        key: 'totalCost',
        label: t('employer.usage.table.costIncVat'),
        type: ColumnType.NUMBER,
        sortable: true,
        transformData: (member) => {
          return <TotalCostBreakdown member={member} />
        },
      },
    ],
    [t]
  )

  const filters = useMemo<Filter[]>(
    () => [
      {
        key: 'chargedAbroad',
        placeholder: t('employer.usage.table.filter.region.placeholder'),
        type: FilterTypes.Option,
        options: [
          {
            key: 'NULL',
            label: t('employer.usage.table.filter.region.all'),
          },
          {
            key: 'true',
            label: t('employer.usage.table.region.europe'),
          },
          {
            key: 'false',
            label: t('employer.usage.table.region.belgium'),
          },
        ],
      },
      {
        key: 'hasCharger',
        placeholder: t('employer.usage.table.filter.homeCharger.placeholder'),
        type: FilterTypes.Option,
        options: [
          {
            key: 'NULL',
            label: t('employer.usage.table.filter.homeCharger.all'),
          },
          {
            key: 'true',
            label: t('employer.usage.table.homeCharger.yes'),
          },
          {
            key: 'false',
            label: t('employer.usage.table.homeCharger.no'),
          },
        ],
      },
      {
        key: 'dateRange',
        type: FilterTypes.DateRange,
      },
    ],
    [t]
  )

  // -- Data --
  const [, exportSessions] = useAuthMemberAxios(
    {
      url: Urls.employerChargingSessionsExport,
      method: 'POST',
      params: {
        filters: {
          ...filterValues,
        },
      },
      responseType: 'blob',
    },
    { manual: true }
  )

  const handleExportSessions = async () => {
    try {
      trackEvent(EventType.Click, 'export_overview')
      setExportLoading(true)
      const response = await exportSessions()

      // Save file
      downloadBlob(
        response.data,
        t('employer.chargingSessions.title').toLocaleLowerCase() +
          format(new Date(), '_dd_MM_yyyy-HH_mm'),
        '.xlsx'
      )
    } catch {
      toast.error(t('employer.chargingSessions.export.error'))
    }

    setExportLoading(false)
  }

  return (
    <ContentContainer>
      <StContainer>
        <StTitle>{t('employer.chargingSessions.title')}</StTitle>
        <StHeader>
          <Filters
            key={'usage-data-table-filters'}
            filters={filters}
            filterValues={filterValues}
            withSearch
            searchPlaceholder={t(
              'employer.usage.table.filter.search.placeholder'
            )}
            onFiltersChange={(filterKey, value) =>
              setFilterValues((oldValues) => ({
                ...oldValues,
                [filterKey]: value,
              }))
            }
            onClearFilters={() => {
              clearFilters()
            }}
            loading={initialLoading && !tableData}
            stickyPosition={navigationContainerBottom}
          >
            <div>
              <ButtonSecondary
                icon={['fass', 'arrow-down-to-line']}
                iconAlignment="left"
                loading={exportLoading}
                onClick={handleExportSessions}
              >
                {t('employer.chargingSessions.export')}
              </ButtonSecondary>
            </div>
          </Filters>
        </StHeader>
        {statistics ? (
          <>
            {/* Statistics values */}
            <StStatisticsRow>
              <StStatistic>
                <StStatisticLabel>
                  {t('employer.usage.statistics.totalCost')}
                </StStatisticLabel>
                <H4>{formatCurrency(statistics?.totalCost ?? 0)}</H4>
                <StStatisticLabel>
                  {t('employer.usage.statistics.totalCost.subtitle')}
                </StStatisticLabel>
              </StStatistic>
              <StStatistic>
                <StStatisticLabel>
                  {t('employer.usage.statistics.averageCostPerKwh')}
                </StStatisticLabel>
                <H4>
                  {formatCurrency(
                    (statistics?.totalCost ?? 0) / (statistics?.totalUsage ?? 1)
                  )}
                </H4>
                <StStatisticLabel>
                  {t('employer.usage.statistics.averageCostPerKwh.subtitle')}
                </StStatisticLabel>
              </StStatistic>
              <StStatistic>
                <StStatisticLabel>
                  {t('employer.usage.statistics.averageCostPerKwh')}
                </StStatisticLabel>
                <H4>
                  <StStatisticIconContainer>
                    <FontAwesomeIcon fontSize={20} icon={['fass', 'house']} />
                  </StStatisticIconContainer>
                  {formatCurrency(
                    !statistics?.totalUsageHomeChargers ||
                      statistics.totalUsageHomeChargers === 0
                      ? 0
                      : (statistics?.totalCostHomeChargers ?? 0) /
                          (statistics?.totalUsageHomeChargers ?? 1)
                  )}
                </H4>
                <StStatisticLabel>
                  {t(
                    'employer.usage.statistics.averageCostPerKwh.subtitle.homeCharger'
                  )}
                </StStatisticLabel>
              </StStatistic>
              <StStatistic>
                <StStatisticLabel>
                  {t('employer.usage.statistics.averageCostPerKwh')}
                </StStatisticLabel>
                <H4>
                  <StStatisticIconContainer>
                    <FontAwesomeIcon
                      fontSize={20}
                      icon={['fass', 'charging-station']}
                    />
                  </StStatisticIconContainer>
                  {formatCurrency(
                    !statistics?.totalUsageWithoutHomeChargers ||
                      statistics.totalUsageWithoutHomeChargers === 0
                      ? 0
                      : (statistics?.totalCostWithoutHomeChargers ?? 0) /
                          (statistics?.totalUsageWithoutHomeChargers ?? 1)
                  )}
                </H4>
                <StStatisticLabel>
                  {t(
                    'employer.usage.statistics.averageCostPerKwh.subtitle.noHomeCharger'
                  )}
                </StStatisticLabel>
              </StStatistic>
            </StStatisticsRow>

            {/* Statistic fractions */}

            <StStatistics>
              <StatisticCard
                title={t('employer.usage.statistics.chargingBreakdown')}
                subTitle={
                  filterValues.dateFrom && filterValues.dateTo
                    ? formatDateRange(
                        {
                          start: filterValues.dateFrom as Date,
                          end: filterValues.dateTo as Date,
                        },
                        dateFnsLocale
                      )
                    : undefined
                }
              >
                <UsageFractions statistics={statistics} />
              </StatisticCard>
              <StatisticCard
                title={t('employer.usage.statistics.chargingSpeed')}
                subTitle={
                  filterValues.dateFrom && filterValues.dateTo
                    ? formatDateRange(
                        {
                          start: filterValues.dateFrom as Date,
                          end: filterValues.dateTo as Date,
                        },
                        dateFnsLocale
                      )
                    : undefined
                }
              >
                <ChargingSpeedFractions statistics={statistics} />
              </StatisticCard>
              <StatisticCard
                title={t('employer.usage.statistics.extraCosts')}
                subTitle={
                  filterValues.dateFrom && filterValues.dateTo
                    ? formatDateRange(
                        {
                          start: filterValues.dateFrom as Date,
                          end: filterValues.dateTo as Date,
                        },
                        dateFnsLocale
                      )
                    : undefined
                }
              >
                <CostPerTypeFractions statistics={statistics} />
              </StatisticCard>
            </StStatistics>
          </>
        ) : initialLoading ? (
          <UsageStatisticsLoader />
        ) : undefined}
        <DataTable<MemberForUsageReport>
          columns={columns}
          hrefGenerator={(member) =>
            replaceParametersInPath(EmployerRoutes.EmployeeDetail, {
              blossomId: member.blossomId,
            })
          }
          emptyFiltersTitle={t(
            'employer.chargingSessions.table.empty-filters-title'
          )}
          emptyTitle={t('employer.chargingSessions.table.empty-title')}
          emptyFiltersDescription={t(
            'employer.chargingSessions.table.empty-filters-description'
          )}
          emptyDescription={t(
            'employer.chargingSessions.table.empty-description'
          )}
        />
      </StContainer>
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: 70px;
`

export const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  & > * {
    flex: 1 0 0;
  }
`

const StTitle = styled(H4)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StStatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StStatistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

const StStatisticLabel = styled(BodyExtraSmallRegular)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`

const StStatisticIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  margin-right: 6px;
`

export const StStatistics = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  > * {
    flex: 1 0 0;
  }
`

export const StPageActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  flex-grow: 0;
`
