import { useTranslation } from 'react-i18next'

import { Fractions } from '../../../components/statistics/Fractions'
import {
  formatCurrency,
  statisticsCostPerTypeData,
} from '../../utils/statistics'

import type { ChargingSessionStatistics } from '../types/types'

type CostPerTypeFractionsProps = {
  statistics?: ChargingSessionStatistics | null
}

export const CostPerTypeFractions = ({
  statistics,
}: CostPerTypeFractionsProps) => {
  const { t } = useTranslation()

  const { startCostPercentage, timeCostPercentage } =
    statisticsCostPerTypeData(statistics)

  // Calculate normalized percentages for just time and start costs
  const totalCostPercentage = timeCostPercentage + startCostPercentage
  const normalizedTimeCostPercentage =
    (timeCostPercentage /
      (totalCostPercentage === 0 ? 1 : totalCostPercentage)) *
    100
  const normalizedStartCostPercentage =
    (startCostPercentage /
      (totalCostPercentage === 0 ? 1 : totalCostPercentage)) *
    100

  return (
    <Fractions
      data={[
        {
          title: t('employer.usage.costPerTypeFractions.time'),
          formattedAmount: formatCurrency(
            statistics?.totalCostPerPriceLineType?.Time ?? 0
          ),
          subText: t('employer.usage.costPerTypeFractions.time.subtext', {
            percentage: timeCostPercentage.toFixed(2),
          }),
          width:
            normalizedTimeCostPercentage === 0
              ? 50
              : normalizedTimeCostPercentage,
          total: statistics?.totalCost ?? 0,
          backgroundColor: '#E6EAEF',
          icon: ['fass', 'square-parking'],
        },
        {
          title: t('employer.usage.costPerTypeFractions.start'),
          formattedAmount: formatCurrency(
            statistics?.totalCostPerPriceLineType?.Start ?? 0
          ),
          subText: t('employer.usage.costPerTypeFractions.start.subtext', {
            percentage: startCostPercentage.toFixed(2),
          }),
          width:
            normalizedStartCostPercentage === 0
              ? 50
              : normalizedStartCostPercentage,
          total: statistics?.totalCost ?? 0,
          backgroundColor: '#F3F5F7',
          icon: ['fass', 'location-pin-lock'],
        },
      ]}
      disabled={!statistics?.totalCost}
      disabledMessage={t('employer.chargingSessions.stats.no-data')}
    />
  )
}
