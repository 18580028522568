import { Form, Formik } from 'formik'
import { PhoneNumberUtil } from 'google-libphonenumber'
import IBAN from 'iban'
import { useRef } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import * as Yup from 'yup'
import 'yup-phone-lite'

import { EmployeeRoutes } from '../../../routing/routes'
import { breakpoints } from '../../../theme/layout/breakpoints'
import { EventType, useTracking } from '../../analytics/hooks/useTracking'
import { shallowEqual } from '../../core/lib/shallowEqual'
import { useMember } from '../../member/hooks/useMember'
import { useChangeLanguage } from '../../translations/hooks/useChangeLanguage'
import { useUser } from '../../user/hooks/useUser'
import { ButtonPrimary } from '../button/ButtonPrimary'
import { Input } from '../form/Input'
import { PhoneInput } from '../form/phone-input/PhoneInput'
import { Select } from '../form/Select'
import { FormCardHeader } from '../form-card/FormCardHeader'
import { DetailPageHeader } from '../general/DetailPageHeader'

import type { SupportedLanguage } from '../../translations/types/Languages'
import type { AxiosPromise } from 'axios'
import type { Language } from 'database'
import type { FormikProps } from 'formik'
import type { UserWithMembers } from 'types'

export type PersonalDataEditValues = {
  firstName: string
  lastName: string
  email: string
  tel: string
  language: Language
  iban: string
}

type PersonalDataEditProps = {
  user: UserWithMembers
  updateUser: (data: PersonalDataEditValues) => AxiosPromise<UserWithMembers>
  disabled: boolean
  disableEdit?: boolean
  title?: string
  editable: boolean
  setEditable: (editable: boolean) => void
  showHeader?: boolean
}

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch {
    return false
  }
}

export const PersonalDataEdit = ({
  user,
  disabled,
  updateUser,
  disableEdit,
  title,
  editable,
  setEditable,
  showHeader,
}: PersonalDataEditProps) => {
  // -- Hooks --
  const { t, i18n } = useTranslation()
  const { changeLanguage } = useChangeLanguage()
  const { trackEvent } = useTracking()
  const { user: currentUser } = useUser()
  const { currentMember } = useMember()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const navigate = useNavigate()

  // -- Vars --
  const isEmployeeDetail = currentUser.id !== user.id
  const isAdmin = currentMember.roles.includes('Admin')
  const eventName = isEmployeeDetail ? 'employee' : 'personal'

  const formRef = useRef<
    FormikProps<{
      email: string
      firstName: string
      lastName: string
      tel: string
      language: Language
      iban: string
    }>
  >(null)

  const isProduction = import.meta.env.VITE_ENV === 'production'

  const initialValues: PersonalDataEditValues = {
    email: user.email || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    tel: user.tel || '+32',
    language: (i18n.language === 'cimode' && !isProduction
      ? i18n.language
      : user.language || i18n.language) as Language,
    iban: user.iban || '',
  }

  const telValidation = Yup.string().test(
    'phone',
    t('onboarding.account.tel.error.invalid'),
    (value) => isPhoneValid(value || '')
  )

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('onboarding.account.firstName.error')),
    lastName: Yup.string().required(t('onboarding.account.lastName.error')),
    tel: isEmployeeDetail
      ? telValidation.required(t('onboarding.account.tel.error'))
      : telValidation,
    iban:
      !isAdmin || isEmployeeDetail
        ? Yup.string()
            .test({
              name: 'ibanCheck',
              message: t('profile.personal-data.iban.error'),
              test: (value) => (value ? IBAN.isValid(value) : true),
            })
            .required(t('profile.personal-data.iban.required'))
        : Yup.string().nullable(),
  })

  // -- Handlers --
  const handleOpen = () => {
    setEditable(true)
    trackEvent(EventType.Click, `edit_${eventName}_data`)
  }

  const handleClose = () => {
    setEditable(false)
    formRef.current?.resetForm()
    trackEvent(EventType.Click, `cancel_edit_${eventName}_data`)
  }

  const handleSubmit = async (values: PersonalDataEditValues) => {
    if ((values.language as SupportedLanguage) === 'cimode') {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { language, ...valuesWithoutLanguage } = values

      try {
        await updateUser({ ...valuesWithoutLanguage, language: user.language })
      } catch {
        toast.error(t('profile.personal-data.error'))
      }
    } else {
      try {
        await updateUser(values)
      } catch {
        toast.error(t('profile.personal-data.error'))
      }
    }

    if (!isEmployeeDetail) {
      changeLanguage(values.language)
    }

    setEditable(false)
    formRef.current?.resetForm({
      values: values,
    })
    trackEvent(EventType.Submit, `update_${eventName}_data`)
  }

  // -- Render --
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({
        handleChange,
        errors,
        values,
        touched,
        handleBlur,
        setFieldValue,
        resetForm,
        setFieldTouched,
      }) => (
        <>
          {showHeader && (
            <DetailPageHeader
              title={t('profile.personal-data.title')}
              topTitle={t('employee.profile.title')}
              onBack={
                isDesktop ? undefined : () => navigate(EmployeeRoutes.Profile)
              }
              actions={
                editable
                  ? [
                      {
                        name: 'edit',
                        icon: ['fasr', 'xmark-large'],
                        onClick: () => {
                          handleClose()
                        },
                      },
                    ]
                  : [
                      {
                        name: 'edit',
                        icon: ['fasr', 'pen'],
                        onClick: () => {
                          handleOpen()
                        },
                      },
                    ]
              }
            />
          )}
          <StForm>
            {isDesktop && (
              <FormCardHeader
                disableEdit={disableEdit}
                disableSubmit={shallowEqual(initialValues, values) || disabled}
                disableCancel={disabled}
                handleSetEditable={handleOpen}
                editable={editable}
                title={title}
                handleClose={() => {
                  resetForm()
                  handleClose()
                }}
              />
            )}

            <Input
              name="firstName"
              label={t('profile.personal-data.firstName')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.firstName}
              touched={editable && touched.firstName}
              value={values.firstName}
              readOnly={!editable}
              disabled={disabled}
            />
            <Input
              name="lastName"
              label={t('profile.personal-data.lastName')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.lastName}
              touched={editable && touched.lastName}
              value={values.lastName}
              readOnly={!editable}
              disabled={disabled}
            />

            {!isEmployeeDetail && (
              <Input
                name="email"
                type="email"
                label={t('profile.personal-data.email')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={editable && touched.email}
                value={values.email}
                disabled
                readOnly={!editable}
              />
            )}

            <PhoneInput
              type="tel"
              name="tel"
              value={values.tel}
              error={errors.tel}
              touched={touched.tel}
              onChange={(value) => {
                setFieldValue('tel', value)

                setTimeout(() => {
                  // Mark field as touched in next render cycle -> this fixes a bug on mobile
                  setFieldTouched('tel')
                }, 50)
              }}
              onBlur={handleBlur}
              disabled={disabled}
              label={t('profile.personal-data.tel')}
              readOnly={!editable}
            />

            {(!isAdmin || isEmployeeDetail) && (
              <Input
                type="text"
                name="iban"
                value={!values.iban && !editable ? '-' : values.iban}
                error={errors.iban}
                touched={touched.iban}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                readOnly={!editable}
                label={t('profile.personal-data.iban')}
                placeholder={t('profile.personal-data.iban.placeholder')}
              />
            )}

            {!isEmployeeDetail && (
              <Select
                label={t('profile.personal-data.language')}
                value={values.language}
                onChange={(value) => setFieldValue('language', value)}
                options={
                  [
                    {
                      key: 'nl',
                      label: 'Nederlands',
                    },
                    {
                      key: 'fr',
                      label: 'Français',
                    },
                    {
                      key: 'en',
                      label: 'English',
                    },
                    //  Don't show in production
                    isProduction
                      ? undefined
                      : {
                          key: 'cimode',
                          label: 'Debug mode',
                        },
                  ].filter(Boolean) as { key: string; label: string }[]
                }
                readOnly={!editable}
                disabled={disabled}
              />
            )}

            {!isDesktop && editable && (
              <StMobileSubmitButton
                compact
                type="submit"
                disabled={shallowEqual(initialValues, values) || disabled}
              >
                {t('profile.personal-data.save')}
              </StMobileSubmitButton>
            )}
          </StForm>
        </>
      )}
    </Formik>
  )
}

const StForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  position: relative;

  /* Extra padding for button height + gap height */
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space17};

  @media ${breakpoints.desktop} {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    grid-template-columns: 1fr 1fr;

    border: 1px solid ${({ theme }) => theme.theme.colors['nonary-7']};
    border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StMobileSubmitButton = styled(ButtonPrimary)`
  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space12});
  position: fixed;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
