import { RemoveScroll } from 'react-remove-scroll'
import { styled } from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { Skeleton } from '../../../components/Skeleton'
import { SkeletonContentLoader } from '../../../components/SkeletonContentLoader'

export function EmployeeSmartChargingSettingsSkeleton() {
  const isDesktop = useMediaQuery(breakpoints.desktop)

  return (
    <RemoveScroll>
      {isDesktop ? (
        <EmployeeSmartChargingSettingsDesktopSkeleton />
      ) : (
        <EmployeeSmartChargingSettingsMobileSkeleton />
      )}
    </RemoveScroll>
  )
}

function EmployeeSmartChargingSettingsMobileSkeleton() {
  return (
    <StContainer>
      <StRow>
        <Skeleton height={24} width="30%" />
        <Skeleton height={24} width="36%" />
      </StRow>
      <StRow>
        <Skeleton height={24} width="30%" />
        <Skeleton height={24} width="36%" />
      </StRow>
      <StRow>
        <Skeleton height={24} width="30%" />
        <Skeleton height={24} width="36%" />
      </StRow>
      <StRow>
        <Skeleton height={24} width="70%" />
        <Skeleton height={24} width="15%" />
      </StRow>
      <StRow>
        <Skeleton height={24} width="27%" />
        <Skeleton height={24} width="45%" />
      </StRow>
    </StContainer>
  )
}

function EmployeeSmartChargingSettingsDesktopSkeleton() {
  return (
    <SkeletonContentLoader viewBox="0 0 1240 416">
      <rect x="0%" y="0%" rx="8" ry="8" width="100%" height="100%" />
    </SkeletonContentLoader>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

const StRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`
