import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deepEqual } from 'fast-equals'
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Map as MapBox, Marker } from 'react-map-gl'
import { styled } from 'styled-components'
import * as Yup from 'yup'

import { breakpoints } from '../../../../../theme/layout/breakpoints'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { BodyMediumSemiBoldCss } from '../../../../components/typography'
import { useGeoLocation } from '../../../../location/hooks/useGeoLocation'
import { useMember } from '../../../../member/hooks/useMember'
import { useUser } from '../../../../user/hooks/useUser'
import { StCurrentLocation } from '../../../map/components/Map'
import { MapActions } from '../../../map/components/MapActions'
import { FloatingContainerButton } from '../../components/FloatingContainerButton'
import { OnboardingStepTitle } from '../../components/OnboardingStepTitle'
import { PersonalAddressSearch } from '../../components/PersonalAddressSearch'
import { useOnboarding } from '../../hooks/useOnboarding'
import { formatAddress } from '../../util/address'

import type {
  AddressData,
  PartialAddressData,
} from '../../../../components/form/address-search/types'
import type { PartialMapboxAddress } from '../../components/PersonalAddressSearch'
import type { MapRef } from 'react-map-gl'

export const InstallationAddressStep = () => {
  // -- Hooks --
  const { t } = useTranslation()
  const { handleNext, setLoading, values: onboardingValues } = useOnboarding()
  const { watchLocation, location, locationPermissionGranted } =
    useGeoLocation()
  const { user } = useUser()
  const { currentMember } = useMember()

  // -- State --
  const [mapRef, setMapRef] = useState<MapRef | null>(null)
  const [address, setAddress] = useState<PartialAddressData | AddressData>(
    onboardingValues.personalData.address
  )
  const [showLocationPicker, setShowLocationPicker] = useState(false)
  const [viewState, setViewState] = useState({
    longitude: 4.3517,
    latitude: 50.8503,
    zoom: 7,
  })

  // -- Data --
  const [, execute] = useAuthMemberAxios(
    {
      url: Urls.InstallationAddress,
      method: 'POST',
    },
    {
      manual: true,
    }
  )

  //  -- Vars --
  const title = useMemo(() => {
    if (
      currentMember.hcpContractId &&
      currentMember.mspContractId &&
      user.hcpStatus.hasExistingDevice
    ) {
      return t('onboarding.installation-address.msp-and-migrate-hcp.title')
    }

    if (currentMember.hcpContractId && currentMember.mspContractId) {
      return t('onboarding.installation-address.msp-and-hcp.title')
    }

    return t('onboarding.installation-address.msp-only.title')
  }, [currentMember, user])

  const inputLabel = useMemo(() => {
    if (
      currentMember.hcpContractId &&
      currentMember.mspContractId &&
      user.hcpStatus.hasExistingDevice
    ) {
      return t('onboarding.installation-address.msp-and-migrate-hcp.label')
    }

    if (currentMember.hcpContractId && currentMember.mspContractId) {
      return t('onboarding.installation-address.msp-and-hcp.label')
    }

    return t('onboarding.installation-address.msp-only.label')
  }, [currentMember, user])

  const addressString = formatAddress(address)

  const validationSchema = Yup.object().shape({
    installationAddressSearch: Yup.string().required(
      t('onboarding.account.address.error')
    ),
  })

  // -- Effects --
  useEffect(() => {
    watchLocation()
  }, [])

  // -- Handlers --
  const handleSubmit = async (
    values: typeof onboardingValues.personalData,
    coordinates?: {
      longitude?: number
      latitude?: number
    }
  ) => {
    if (!address) return

    if (
      (!address.latitude || !address.longitude) &&
      (!coordinates?.latitude || !coordinates?.longitude)
    ) {
      setShowLocationPicker(true)
      return
    }

    setLoading(true)

    const newValues = {
      ...values,
      address: {
        ...address,
        longitude: address.longitude || coordinates?.longitude,
        latitude: address.latitude || coordinates?.latitude,
      } as AddressData,
    }

    if (!deepEqual(newValues, onboardingValues.personalData)) {
      try {
        await execute({
          data: newValues,
        })
      } catch {
        setLoading(false)
        return console.error('Failed to save installation address')
      }
    }

    handleNext({
      ...onboardingValues,
      personalData: newValues,
    })
  }

  const handleSelectAddress = (mapboxAddress: PartialMapboxAddress | null) => {
    setAddress({
      street:
        mapboxAddress?.features?.[0].properties.context.address.street_name ||
        '',
      number:
        mapboxAddress?.features?.[0].properties.context.address
          .address_number || '',
      city: mapboxAddress?.features?.[0].properties.context.place?.name || '',
      postcode:
        mapboxAddress?.features?.[0].properties.context.postcode?.name || '',
      country:
        mapboxAddress?.features?.[0].properties.context.country.country_code ||
        '',
      countryName:
        mapboxAddress?.features?.[0].properties.context.country.name || '',
      longitude: mapboxAddress?.features?.[0]?.geometry?.coordinates?.[0],
      latitude: mapboxAddress?.features?.[0]?.geometry?.coordinates?.[1],
      region:
        mapboxAddress?.features?.[0].properties.context.region?.name || '',
      regionCode:
        mapboxAddress?.features?.[0].properties.context.region?.region_code ||
        '',
    })
  }

  return (
    <Formik
      initialValues={{
        ...onboardingValues.personalData,
        installationAddressSearch: addressString ?? '',
      }}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) =>
        showLocationPicker ? (
          <StMapContainer>
            <OnboardingStepTitle>
              {t('onboarding.account.location-selection.title')}
            </OnboardingStepTitle>
            <StMapWrapper>
              <StMap>
                <MapBox
                  {...viewState}
                  reuseMaps
                  dragRotate={false}
                  touchPitch={false}
                  minZoom={3}
                  pitchWithRotate={false}
                  ref={(ref) => setMapRef(ref)}
                  onMove={(event) => setViewState(event.viewState)}
                  mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
                  style={{
                    width: '100dvw',
                    height: '100%',
                  }}
                  mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
                  onRender={(event) => {
                    event.target.resize()
                  }}
                  scrollZoom={{ around: 'center' }}
                >
                  {locationPermissionGranted && (
                    <Marker
                      latitude={location.latitude}
                      longitude={location.longitude}
                    >
                      <StCurrentLocation />
                    </Marker>
                  )}
                </MapBox>

                <StMarkerWrapper>
                  <StMarker $selected={true} $clusterSize={4}>
                    <FontAwesomeIcon icon={['fass', 'house']} fontSize={18} />
                  </StMarker>
                </StMarkerWrapper>

                <MapActions map={mapRef} />
              </StMap>
            </StMapWrapper>
            <FloatingContainerButton
              disabled={isSubmitting}
              onClick={() => {
                const coordinates = mapRef?.getMap().getCenter()

                handleSubmit(values, {
                  longitude: coordinates?.lng,
                  latitude: coordinates?.lat,
                })
              }}
              title={t('onboarding.account.location-selection.submit')}
            />
          </StMapContainer>
        ) : (
          <StContainer>
            <OnboardingStepTitle>{title}</OnboardingStepTitle>

            <Form>
              <StFormContainer>
                <PersonalAddressSearch
                  name="installationAddressSearch"
                  displayValue={addressString}
                  label={inputLabel}
                  types={['address']}
                  onSelect={(address) => {
                    setFieldValue(
                      'installationAddressSearch',
                      address?.features?.[0].properties.context.address
                        .street_name
                    )

                    handleSelectAddress(address)

                    setTimeout(() => {
                      setFieldTouched('installationAddressSearch')
                    }, 50)
                  }}
                  onBlur={() => {
                    setFieldTouched('installationAddressSearch')
                  }}
                  disabled={isSubmitting}
                  error={errors.installationAddressSearch}
                  touched={touched.installationAddressSearch}
                  key="installationAddressSearch"
                />
              </StFormContainer>

              <FloatingContainerButton
                title={t('onboarding.account.submit')}
                disabled={!address.street}
              />
            </Form>
          </StContainer>
        )
      }
    </Formik>
  )
}

const StContainer = styled.div`
  padding-bottom: ${({ theme }) =>
    `calc(var(--inset-bottom, ${theme.UI.SpacingPx.Space6}) + var(--sticky-button-container-height))`};
`

const StMapContainer = styled.div`
  margin-bottom: calc(
    var(--inset-bottom, 0) + var(--sticky-button-container-height)
  );

  height: calc(100vh - var(--navigation-height) - 128px);

  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const StFormContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;

  @media ${breakpoints.desktop} {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

    margin: ${({ theme }) => theme.UI.SpacingPx.Space15} 0;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

const StMapWrapper = styled.div`
  flex: 1;

  position: relative;
`

const StMap = styled.div`
  flex: 1;

  height: 100%;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const StMarkerWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-2%);

  pointer-events: none;
`

const StMarker = styled.div<{
  $selected?: boolean
  $clusterSize?: number
  $available?: boolean
}>`
  height: calc(40px + ${({ $clusterSize = 0 }) => $clusterSize}px);
  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  ${BodyMediumSemiBoldCss}

  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.theme.colors['secondary-1']
      : theme.theme.colors['primary-1']};
  color: ${({ theme, $selected }) =>
    $selected ? theme.theme.colors['primary-1'] : theme.theme.colors.black};

  border-radius: 999px;

  cursor: pointer;

  margin-bottom: 7px;

  &::after {
    content: '';

    aspect-ratio: 1 /1;

    position: absolute;
    bottom: 0px;
    z-index: -1;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid
      ${({ theme, $selected }) =>
        $selected
          ? theme.theme.colors['secondary-1']
          : theme.theme.colors['primary-1']};
  }
`
