import { useTranslation } from 'react-i18next'

import { EmployerRoutes } from '../../routing/routes'
import { Navigation } from '../components/navigation/Navigation'
import { PageLayout } from '../components/page-layout'
import { useUnreadDocumentsCount } from '../employer/documents/hooks/useUnreadDocumentsCount'
import { usePermissions } from '../employer/hooks/usePermissions'
import { EmployerProfileMenu } from '../employer/profile/components/EmployerProfileMenu'

import type {
  NavigationOptions,
  NavigationItemType,
} from '../components/navigation/Navigation'
import type { PropsWithChildren } from 'react'

export const AuthenticatedNavigation = ({ children }: PropsWithChildren) => {
  // -- Hooks --
  const { t } = useTranslation()
  const { unreadCount } = useUnreadDocumentsCount()
  const { hasPermission } = usePermissions()

  // -- Vars --
  const navigationItems: NavigationItemType[] = [
    { name: t('admin.navigation.home'), url: EmployerRoutes.Root },
    ...(hasPermission('MANAGE_EMPLOYEES')
      ? [
          {
            name: t('admin.navigation.employees'),
            url: EmployerRoutes.Employees,
          },
        ]
      : []),
    ...(hasPermission('VIEW_USAGE_DATA')
      ? [
          {
            name: t('admin.navigation.usage'),
            url: EmployerRoutes.Usage,
          },
        ]
      : []),
    ...(hasPermission('MANAGE_EMPLOYEES')
      ? [
          {
            name: t('admin.navigation.contracts'),
            url: EmployerRoutes.Contracts,
          },
        ]
      : []),
    {
      name: t('admin.navigation.documents'),
      url: EmployerRoutes.Documents,
      badge: unreadCount > 0 ? unreadCount : undefined,
    },
  ]

  const navigationOptions: NavigationOptions = {
    logo: true,
    navigationItems,
    rightItem: <EmployerProfileMenu />,
  }

  return (
    <PageLayout navigation={<Navigation options={navigationOptions} />}>
      {children}
    </PageLayout>
  )
}
